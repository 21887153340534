import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../components/AppLayout';
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MediaQuery from 'react-responsive'

import image_1_5 from '../assets/image_1_5.png'
import image_1_6 from '../assets/image_1_6.png'
import image_1_7 from '../assets/image_1_7.png'
import image_1_8 from '../assets/image_1_8.png'
import image_2_1 from '../assets/image_2_1.png'
import image_2_2 from '../assets/image_2_2.png'
import image_2_3 from '../assets/image_2_3.png'
import image_2_4 from '../assets/image_2_4.png'
import image_2_5 from '../assets/image_2_5.png'
import image_2_6 from '../assets/image_2_6.png'
import image_2_7 from '../assets/image_2_7.png'
import partner from '../assets/partner.png'
import partnerM from '../assets/partnerM.png'

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { useTranslation } from "react-i18next";
const Products2 = () => {
        const { t, i18n } = useTranslation()
        const demoLink = "https://tiquitaca-fbd1b.web.app/#/"

        return (
                <AppLayout>
                        <MediaQuery minWidth={1224}>
                                <div className="products">
                                        <div>
                                                <div className='productsTop'>
                                                        {i18n.language == 'ko' ?
                                                                <div className='textContents'>
                                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>텍스트 어드벤처 챗봇!</div></Fade>
                                                                        <div className='bold70 marginB10'>티키타카</div>
                                                                        <div className='lineH f18'>누구나 원하는 챗봇을 만들 수 있습니다.</div>
                                                                        <div className='lineH f18'>인공지능이 창조한 세계에서 다양한 모험을 즐기세요!</div>
                                                                        <a href={demoLink} target="_blank" className='demoButton2 demoButtonPosition'>demo 체험하기</a>
                                                                </div>
                                                                :
                                                                <div className='textContents'>
                                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>AI Fueled Text Adventure!</div></Fade>
                                                                        <div className='bold70 marginB10'>Tiquitaca</div>
                                                                        <div className='lineH f18'>Anyone can make a chatbot of their choice.</div>
                                                                        <div className='lineH f18'>Enjoy various adventures in the world created by artificial intelligence!</div>
                                                                        <a href={demoLink} target="_blank" className='demoButton2 demoButtonPosition'>demo</a>
                                                                </div>
                                                        }

                                                </div>
                                        </div>
                                        <div className='productsCenter'>
                                                {/* <Trail >
        <span>Lorem</span>
        <span>Ipsum</span>
        <span>Dolor</span>
        <span>Sit</span>
      </Trail> */}
                                                <div className='rowTop'>
                                                        <Fade top cascade duration={1500} >
                                                                {i18n.language == 'ko' ?
                                                                        <>
                                                                                <div className='bold30 marginB30'>챗봇? 뻔한 스토리, 뻔한 대답!</div>
                                                                                <div className='bold30 marginB30'>지금까지의 챗봇은 너무 지루하지 않으셨나요?</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                                <div className='bold30 marginB30'>Chatbot? Obvious story, Obvious answer!</div>
                                                                                <div className='bold30 marginB30'>Wasn't the chatbot so boring until now?</div>
                                                                        </>
                                                                }
                                                        </Fade>
                                                        <Fade>
                                                                <img className="itemImage" src={image_2_1} />
                                                        </Fade>
                                                </div>
                                                <div className='rowTop'>
                                                        <div className='left'>

                                                        </div>
                                                        <div className='right'>

                                                        </div>
                                                </div>
                                                <div className='bottomBorder' />
                                                <div className='row'>
                                                        <div className='left'>
                                                                {i18n.language == 'ko' ?
                                                                        <>
                                                                                <div className='bold30 marginB10 '>인공지능이 만든 또 다른 나</div>
                                                                                <div className='underLine'></div>
                                                                                <div>100개의 문장 입력으로</div>
                                                                                <div>내가 원하는 성격을 가진 챗봇을 만들어낼 수 있습니다.</div>
                                                                                <div>내가 좋아하는 캐릭터를 둘도 없는 친구로 만드세요!</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                                <div className='bold30 marginB10 '>Another me created by artificial intelligence.</div>
                                                                                <div className='underLine'></div>
                                                                                <div>with 100 sentence inputs </div>
                                                                                <div>I can create a chatbot with the personality I want.</div>
                                                                                <div>Make my favorite character my best friend!</div>
                                                                        </>
                                                                }
                                                        </div>
                                                        <div className='right'>
                                                                <img className="itemImage" src={image_2_2} />
                                                        </div>
                                                </div>
                                                <div className='row'>
                                                        <div className='left'>
                                                                <img className="itemImage" src={image_2_3} />

                                                        </div>
                                                        <div className='right'>
                                                                {i18n.language == 'ko' ?
                                                                        <>
                                                                                <div className='bold30 marginB10 '>나만의 캐릭터와 함께 판타지 모험을!</div>
                                                                                <div className='underLine'></div>
                                                                                <div>텍스트기반 어드벤처 게임을 아시나요?</div>
                                                                                <div>AI가 만들어낸 나만의 가상세계에서 게임을 하듯 모험을 즐기세요. </div>
                                                                                <div>지루한 대화에서 벗어나 흥미롭고, </div>
                                                                                <div>재미있는 판타지로 가득 찬 세계로 초대합니다!</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                                <div className='bold30 marginB10 '>Fantasy adventure with your own character!</div>
                                                                                <div className='underLine'></div>
                                                                                <div>Do you know any text-based adventure games?</div>
                                                                                <div>Enjoy adventures like playing games in your own virtual world created by AI.</div>
                                                                                <div>We invite you to a world full of interesting, </div>
                                                                                <div>fun fantasy, away from boring conversations!</div>
                                                                        </>
                                                                }

                                                        </div>

                                                </div>
                                                <div className='row'>
                                                        <div className='left'>
                                                                {i18n.language == 'ko' ?
                                                                        <>
                                                                                <div className='bold30 marginB10 '>자연스러운 대화, 나만의 비서</div>
                                                                                <div className='underLine'></div>
                                                                                <div className=''>
                                                                                        자연스러워 보이기 위해, 구체적인 답변을 피해왔던 지금까지의 챗봇들!
                                                                                </div>
                                                                                <div className=''>저희 티키타카는 이제 더는 대답을 회피하지 않아요.</div>
                                                                                <div className=''>무엇이든 물어보세요.</div>
                                                                                <div className=''>나만의 친구이자 비서가 될 테니까요.</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                                <div className='bold30 marginB10 '>Natural conversation, my own secretary.</div>
                                                                                <div className='underLine'></div>
                                                                                <div className=''>
                                                                                        Chatbots so far have avoided specific answers to look natural!
                                                                                </div>
                                                                                <div className=''>Our tiki-taka doesn't shy away from answering anymore.</div>
                                                                                <div className=''>Ask me anything.</div>
                                                                                <div className=''>I'll be your friend and secretary.</div>
                                                                        </>
                                                                }
                                                        </div>
                                                        <div className='right'>
                                                                <img className="itemImage" src={image_2_4} />
                                                        </div>
                                                </div>
                                                <div className='bold40 marginB50'>Product Features</div>
                                                <div className='bottomRow'>
                                                {i18n.language == 'ko' ?
                                                        <>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_5} />
                                                                <div className='bold20 marginB10'>Optimized XLM (GPT 6B)</div>
                                                                <div className='textWrap'>Optimized XLM (GPT 6B) 개발로
                                                                        국내에서 가장 뛰어난 NLP기술 보유.
                                                                        GPT3 과 styled model의
                                                                        합성으로 기존 챗봇시장에서 한세대 앞서나간
                                                                        챗봇을 선보입니다.</div>

                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_6} />
                                                                <div className='bold20 marginB10'>Chatbot builder platform</div>
                                                                <div className='textWrap'>다년간 쌓인 GPU관리의 최적화, 로드 밸런싱 기술로 플랫폼 서비스 구축.
                                                                        100문장만 준비되면, 누구나 원하는 스타일의
                                                                        챗봇을 제작 가능한, 구독형 챗봇빌더 플랫폼 서비스를 차후 제공합니다.</div>
                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_7} />
                                                                <div className='bold20 marginB10'>Text to image</div>
                                                                <div className='textWrap'>챗봇과의 대화를 나만의 이미지 북으로 생성합니다. 다양한 캐릭터와 변화무쌍한 스토리를 만드세요. 스토리북 공유플랫폼으로 전세계의 다양한 스토리북을 감상하고 즐길 수 있습니다.</div>
                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                         
                                                        
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_5} />
                                                                <div className='bold20 marginB10'>Optimized XLM (GPT 6B)</div>
                                                                <div className='textWrap'>Optimized XLM (GPT 6B) has the best NLP technology in Korea. Through the synthesis of GPT3 and styled model, we introduce chatbots that are one generation ahead of the existing chatbots market.</div>

                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_6} />
                                                                <div className='bold20 marginB10'>Chatbot builder platform</div>
                                                                <div className='textWrap'>Optimization of GPU management accumulated over many years, building platform service with load balancing technology. If only 100 sentences are prepared, we will provide a subscription chatbot builder platform service that anyone can create a desired style chatbot.</div>
                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_7} />
                                                                <div className='bold20 marginB10'>Text to image</div>
                                                                <div className='textWrap'>Create conversations with chatbots in your own image book. Create diverse characters and ever-changing stories. The storybook sharing platform allows you to enjoy and enjoy various storybooks from all over the world.</div>
                                                        </div>
                                                        </>
                                                        }
                                                </div>

                                                <div className='bottomBorder' />
                                                <div className='bottomEx'>
                                                        <div className='bold40 marginB10 '>Example</div>

                                                        {/* <img className="itemImage" src={image_1_8} /> */}
                                                        <div className='bottomBorder' />

                                                        <img src={partner} />
                                                        {i18n.language=="ko"?
                                                        <a href={demoLink} target="_blank" className='demoButton2 demoButtonPosition'>demo 체험하기</a>
                                                        :
                                                        <a href={demoLink} target="_blank" className='demoButton2 demoButtonPosition'>demo</a>
                                                        
                                                        }
                                                </div>
                                        </div>

                                </div>
                        </MediaQuery>
                        {/*모바일//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                        <MediaQuery maxWidth={1224}>
                                <div className="products">
                                        <div>
                                                <div className='productsTop'>
                                                        <div className='textContents'>
                                                                <Fade left cascade duration={2000} delay={200}><div className='bold20'>텍스트 어드벤처 챗봇!</div></Fade>
                                                                <div className='bold50 marginB10'>티키타카</div>
                                                                <div className='text'>
                                                                        <div className='lineH f16'>누구나 원하는 챗봇을 만들 수 있습니다.</div>
                                                                        <div className='lineH f16'>인공지능이 창조한 세계에서 다양한 모험을 즐기세요!</div>
                                                                </div>
                                                                <a href={demoLink} target="_blank" className='demoButton2 demoButtonPosition'>demo 체험하기</a>
                                                        </div>

                                                </div>
                                        </div>
                                        <div className='productsCenter'>
                                                <div className='bottomBorder' />

                                                <div className='col'>
                                                        <div className='bold30 marginB30'>챗봇? 뻔한 스토리, 뻔한 대답!</div>
                                                        <div className='bold30 marginB30'>지금까지의 챗봇은 너무 지루하지 않으셨나요?</div>
                                                        <Fade>
                                                                <img className="itemImage" src={image_2_1} />
                                                        </Fade>
                                                </div>

                                                <div className='bottomBorder2' />
                                                <div className='col'>
                                                        <div className='bold30 marginB10 '>인공지능이 만든 또 다른 나</div><br />

                                                        원하는 캐릭터의 말투를 100문장만 입력하세요.<br />
                                                        나만의 캐릭터가 만들어집니다.<br />
                                                        환상적이지 않나요?<br />
                                                        항상 뻔한 대답을 벗어난 나만의 챗봇이라니!<br />
                                                        내가 좋아하는 캐릭터를 둘도 없는 친구로 만드세요!<br />
                                                        <img className="itemImage" src={image_2_2} />
                                                </div>
                                                <div className='col'>

                                                        <div className='bold30 marginB10 '>나만의 캐릭터와 함께 판타지 모험을!</div><br />

                                                        텍스트기반 어드벤처 게임을 아시나요?<br />
                                                        AI가 만들어낸 나만의 가상세계에서 게임을 하듯 모험을 즐기세요. <br />
                                                        환상적이지 않나요?<br />
                                                        지루한 대화에서 벗어나 흥미롭고, <br />
                                                        재미있는 판타지로 가득 찬 세계로 초대합니다!<br />
                                                        <img className="itemImage" src={image_2_3} />

                                                </div>
                                                <div className='col'>
                                                        <div className='bold30 marginB10 '>자연스러운 대화, 나만의 비서</div><br />
                                                        <div className=''>
                                                                자연스러워 보이기 위해, 구체적인 답변을 피해왔던 지금까지의 챗봇들!
                                                        </div>
                                                        <div className=''>저희 티키타카는 이제 더는 대답을 회피하지 않아요.</div>
                                                        <div className=''>무엇이든 물어보세요.</div>
                                                        <div className=''>나만의 친구이자 비서가 될 테니까요.</div>
                                                        <img className="itemImage" src={image_2_4} />
                                                </div>
                                                <div className='bold40 marginB50'>Product Features</div>
                                                <div className='bottomRow'>
                                                        {i18n.language=='ko'?
                                                        <>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_5} />
                                                                <div className='bold20 marginB10'>Optimized XLM (GPT 6B)</div>
                                                                <div className='textWrap'>Optimized XLM (GPT 6B) 개발로
                                                                        국내에서 가장 뛰어난 NLP기술 보유.
                                                                        GPT3 과 styled model의
                                                                        합성으로 기존 챗봇시장에서 한세대 앞서나간
                                                                        챗봇을 선보입니다.</div>

                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_6} />
                                                                <div className='bold20 marginB10'>Chatbot builder platform</div>
                                                                <div className='textWrap'>다년간 쌓인 GPU관리의 최적화, 로드 밸런싱 기술로 플랫폼 서비스 구축.
                                                                        100문장만 준비되면, 누구나 원하는 스타일의
                                                                        챗봇을 제작 가능한, 구독형 챗봇빌더 플랫폼 서비스를 차후 제공합니다.</div>
                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_2_7} />
                                                                <div className='bold20 marginB10'>Text to image</div>
                                                                <div className='textWrap'>챗봇과의 대화를 나만의 이미지 북으로 생성합니다. 다양한 캐릭터와 변화무쌍한 스토리를 만드세요. 스토리북 공유플랫폼으로 전세계의 다양한 스토리북을 감상하고 즐길 수 있습니다.</div>
                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                    
                                                        </>
}
                                                </div>

                                                <div className='bottomBorder' />
                                                <div className='bottomEx'>
                                                        <div className='bold40 marginB10 '>Example</div>

                                                        {/* <img className="itemImage" src={image_1_8} /> */}
                                                        <div className='bottomBorder' />

                                                        <img className="itemImage" src={partnerM} />

                                                        <a href={demoLink} target="_blank" className='demoButton2 demoButtonPosition'>demo 체험하기</a>
                                                </div>
                                        </div>

                                </div>
                        </MediaQuery>
                </AppLayout>
        )
}

Products2.propTypes = {};

export default Products2;