import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import { Link } from 'react-router-dom';
import logo from '../assets/ALI_logo.png'
import logo2 from '../assets/ALI_logo2.png'
import logo2M from '../assets/ALI_logo2M.png'
import roundA from '../assets/roundA.png'
import roundT from '../assets/roundT.png'
import roundG from '../assets/roundG.png'
import MediaQuery from 'react-responsive'
import { useTranslation } from "react-i18next";

const TopNavigation = ({ }) => {
    const [menuToggle1, setMenuToggle1] = useState(false)
        const [menuToggle2, setMenuToggle2] = useState(false)
        const [menuToggle3, setMenuToggle3] = useState(false)
        const [menuToggle4, setMenuToggle4] = useState(false)
        const { t, i18n } = useTranslation()
        const changelanguageToKo = () => i18n.changeLanguage('ko')
            const changelanguageToEn = () => i18n.changeLanguage('en')
        // const styles = useSpring({ opacity: toggle ? 1 : 0 })
        const styles = useSpring({
                opacity: menuToggle1 ? 1 : 0,
                config: { duration: 300 },
                display: menuToggle1 ? 'flex' : 'none',
                backgroundColor:'transparent',
                position:'absolute',
                flexDirection:'column',
        })
        // useEffect(() => {
        //         console.log(menuToggle1)
        // })
        const location = useLocation();
        return (
                <div className='topNavigation'>
                        <div className='left'>
                                <Link to='/AboutUs' state={{from:location.pathname}} className='topMenuButton'>
                                <MediaQuery minWidth={1224}>
                                <img src={logo2} className='logoPosition'/>
                                </MediaQuery>
                                <MediaQuery maxWidth={1224}>
                                {/* <img src={logo2M} className='logoPosition'/> */}
                                </MediaQuery>
                                </Link>
                        </div>
                        <div className='center'>
                        <div style={{ position: 'fixed', right: 80, top:11, zIndex:15,}}>
                        <MediaQuery minWidth={1224}>

                        {i18n.language.indexOf('ko') !=-1 ?
                        <button className='nudeButton' onClick={changelanguageToEn}><span className="bold">한</span>/EN</button>
                        :
                        <button className='nudeButton' onClick={changelanguageToKo}>한/<span className="bold">EN</span></button>
                        }
                        </MediaQuery>
                    </div>
                        </div>
                        <div className='right'>
                                {/* <div onMouseOver={()=>setMenuToggle1(true)} onMouseLeave={()=>setMenuToggle1(false)}>
                                                회사소개
                                        </div> */}
                                {/* <animated.div style={styles}>
                                                asdf
                                        </animated.div> */}
                                {i18n.language=='ko'?
<>
                                <Link to='/AboutUs' state={{from:location.pathname}} className='topMenuButton'>
                                        회사소개
                                </Link>
                                <div>
                                        {/* <Link to='/Products1' state={{from:location.pathname}} className='topMenuButton' onMouseOver={() => setMenuToggle1(true)} onMouseLeave={() => setMenuToggle1(false)}>
                                                주요상품
                                        </Link> */}
                                        <Link  state={{from:location.pathname}} className='topMenuButton' onMouseOver={() => setMenuToggle1(true)} onMouseLeave={() => setMenuToggle1(false)}>
                                                주요상품
                                        </Link>

                                        
                                        <animated.div style={styles} onMouseOver={() => menuToggle1 ? setMenuToggle1(true) : setMenuToggle1(false)} onMouseLeave={() => setMenuToggle1(false)}>
                                                <div className='triangle'></div>
                                                <div className='pMenuContainer'>
                                               
                                                <Link className='pMenuButton' to='/Products1' state={{from:location.pathname}} style={{display:'flex',alignItems:'center'}}><img src={roundA}/>&nbsp;&nbsp;&nbsp;AskMe</Link>
                                                <Link className='pMenuButton' onClick={()=>window.open("https://docs.google.com/document/d/1EljDrFETFhblQRMsNWqT11w5ii8pHpY_F4l-MySapAA/edit?usp=sharing")} style={{display:'flex',alignItems:'center'}}>
<img src={roundT}/>&nbsp;&nbsp;&nbsp;Write Me</Link>
                                                <Link className='pMenuButton' to='/Products2' state={{from:location.pathname}}  style={{display:'flex',alignItems:'center'}}><img src={roundT}/>&nbsp;&nbsp;&nbsp;티키타카</Link>
                                                
                                                <Link className='pMenuButton' to='/Products3' state={{from:location.pathname}}  style={{display:'flex',alignItems:'center'}}><img src={roundG}/>&nbsp;&nbsp;&nbsp;같이워치</Link>
                                                
                                                {/* <Link className='pMenuButton' to='https://www.notion.so/neoali/642678e986814119ae4deb9f575fede4'    style={{display:'flex',alignItems:'center'}}> 
                                                        <a className='pMenuButton' href="https://www.notion.so/neoali/642678e986814119ae4deb9f575fede4" style={{display:'flex',alignItems:'center'}} target="_blank">
                                                        <img src={roundG}/>&nbsp;&nbsp;&nbsp;K-Pop Chat
                                                        </a>
                                                 </Link> */}
                                                </div>
                                        </animated.div>
                                </div>
                                <Link to='/Press' state={{from:location.pathname}} className='topMenuButton'>
                                        보도매체
                                </Link>
                                <Link to='/Vision' state={{from:location.pathname}} className='topMenuButton'>
                                        비전
                                </Link>
                                </>
                                :
                                <>
                                <Link to='/AboutUs' state={{from:location.pathname}} className='topMenuButton'>
                                AboutUs
                                </Link>
                                <div>
                                        {/* <Link to='/Products1' state={{from:location.pathname}} className='topMenuButton' onMouseOver={() => setMenuToggle1(true)} onMouseLeave={() => setMenuToggle1(false)}>
                                                주요상품
                                        </Link> */}
                                        <Link  state={{from:location.pathname}} className='topMenuButton' onMouseOver={() => setMenuToggle1(true)} onMouseLeave={() => setMenuToggle1(false)}>
                                                Products
                                        </Link>
                                        <animated.div style={styles} onMouseOver={() => menuToggle1 ? setMenuToggle1(true) : setMenuToggle1(false)} onMouseLeave={() => setMenuToggle1(false)}>
                                                <div className='triangle'></div>
                                                <div className='pMenuContainer'>
                                               
                                                <Link className='pMenuButton' to='/Products1' state={{from:location.pathname}} style={{display:'flex',alignItems:'center'}}><img src={roundA}/>&nbsp;&nbsp;&nbsp;AskMe</Link>
                                                <Link className='pMenuButton' onClick={()=>window.open("https://docs.google.com/document/d/1EljDrFETFhblQRMsNWqT11w5ii8pHpY_F4l-MySapAA/edit?usp=sharing")} style={{display:'flex',alignItems:'center'}}>
<img src={roundT}/>&nbsp;&nbsp;&nbsp;Write Me</Link>
                                                <Link className='pMenuButton' to='/Products2' state={{from:location.pathname}}  style={{display:'flex',alignItems:'center'}}><img src={roundT}/>&nbsp;&nbsp;&nbsp;Tiquitaca</Link>
                                                <Link className='pMenuButton' to='/Products3' state={{from:location.pathname}}  style={{display:'flex',alignItems:'center'}}><img src={roundG}/>&nbsp;&nbsp;&nbsp;Gachwatch</Link>
                                                <a className='pMenuButton' href="https://www.notion.so/neoali/642678e986814119ae4deb9f575fede4" style={{display:'flex',alignItems:'center'}} target="_blank">
                                                        <img src={roundG}/>&nbsp;&nbsp;&nbsp;K-Pop Chat
                                                        </a>
                                                </div>
                                        </animated.div>
                                </div>
                                <Link to='/Press' state={{from:location.pathname}} className='topMenuButton'>
                                Press
                                </Link>
                                <Link to='/Vision' state={{from:location.pathname}} className='topMenuButton'>
                                Vision
                                </Link>
                                </>
}
                        </div>
                </div>
        )

}

TopNavigation.propTypes = {};

export default TopNavigation;
