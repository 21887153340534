import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../components/AppLayout';
import about1 from '../assets/about1.png'
import about1M from '../assets/about1M.png'
import about2 from '../assets/about2.png'
import about3 from '../assets/about3.png'
import lmh from '../assets/lmh.png'
import lsy from '../assets/lsy.png'
import jes from '../assets/jes.png'
import talent from '../assets/talent.jpg'
import tk from '../assets/tk.jpg'
import Fade from 'react-reveal/Fade';
import { useSpring, animated, useTransition, config, Transition } from 'react-spring'
import MediaQuery from 'react-responsive'
import { useTranslation } from "react-i18next";

const AboutUs = () => {
    const { t,i18n } = useTranslation()
    // const styles = useSpring({

        //         from: { opacity: 1 },
        //         enter: { opacity: 0 },
        //         leave: { opacity: 0 },
        //         delay: 2000,
        //         config: config.molasses,
        //  })
        return (
                <AppLayout>
                        <MediaQuery minWidth={1224}>

                                <div className='aboutUs'>
                                        <div className='aboutUsTop'>
                                                <div>
                                                {i18n.language=='ko'?
                                                        <>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>자연어처리 인공지능 전문 회사</div></Fade>
                                                        <div className='bold50 marginB10'><span style={{fontSize:45}}>(주)</span>ALI는 어떤 회사인가요?</div>
                                                        <div className='lineH f18'>
                                                                <br />
                                                                ALI는 데이터 의존성을 줄이는 딥러닝 학습 기술의 고도화를  목표로,<br />
                                                                인공지능 업계의 새로운 패러다임을 선도하는 기술기반 기업입니다.<br /><br />
                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>A company specializing in NLP</div></Fade>
                                                        <div className='bold50 marginB10'>ALI is?</div>
                                                        <div className='lineH f18'>
                                                                <br />
                                                                ALI aims to advance deep learning technologies that reduce data dependency.<br />
                                                                It is a technology-based company that leads a new paradigm in the AI industry.<br /><br />
                                                        </div>
                                                        </>
}

                                                </div>

                                        </div>

                                        <div className='aboutUsCenter'>

                                                {/* <img src={image_1_1} className='image1' /> */}
                                                {i18n.language=='ko'?
                                                <>
                                                <div className='bold50 marginB30'>모든 대화형 인공지능기술의 통합</div>
                                                <div className='f20 marginB30'>ALI는 요약, 대화, 지식 등 모든 자연어 처리 분야에서 세계 최고를 지향합니다.</div>
                                                </>
                                                :
                                                <>
                                                <div className='bold50 marginB30'>Integration of all interactive AI technologies</div>
                                                <div className='f20 marginB30'>ALI aims to be the world's best in all natural language processing,</div>
                                                <div className='f20 marginB30'>including summarization, conversation, and knowledge.</div>
                                                </>
}
                                                <img src={about1} className='marginB100' />
                                                <div className='row'>
                                                        <div className='left'>
                                                {i18n.language=='ko'?
<>
                                                                <div className='bold30 marginB10'>과학기술의 사회 공헌</div>
                                                                <div className='underLine'></div>
                                                                <div>인공지능의 미래와 현재에 대해 지역사회 세미나를 주기적으로 개최합니다.</div>
                                                                <div>중학교, 고등학교 재능 기부는 저희 ALI의 많은 업무 중에서도 중요한 활동입니다.</div>
                                                                <div>지역사회 발전과 인공지능에 대한 바른 이해를 돕기 위해</div>
                                                                <div>항상 노력하는 ALI입니다.</div>
                                                                </>
                                                                :
                                                                <>
                                                                <div className='bold30 marginB10'>the contribution of science and technology to society</div>
                                                                <div className='underLine'></div>
                                                                <div>We regularly hold community seminars on the future and present of artificial intelligence. </div>
                                                                <div>Giving talent to middle school and high school is one of the most important activities of our ALI.</div>
                                                                <div>To help you develop your community and gain a better understanding of artificial intelligence, </div>
                                                                <div> ALI is always trying.</div>
                                                                </>
}
                                                        </div>

                                                        <div className='right'>

                                                                <div className='tableWrap'>
                                                                        <div className='tableRow'>
                                                                                <div className='bold20'>2021.12</div>
                                                                                <div>과학기술정보통신부장관 표창장</div>
                                                                                <div>표창</div>
                                                                        </div>
                                                                        <div className='tableRow'>
                                                                                <div className='bold20'>2021.12</div>
                                                                                <div>과학기술정보통신부장관 SW연구개발분야 우수상</div>
                                                                                <div>포상</div>
                                                                        </div>
                                                                        <div className='tableRow'>
                                                                                <div className='bold20'>2021.12</div>
                                                                                <div>Sleep AI Challenge 대회</div>
                                                                                <div>1등</div>
                                                                        </div>
                                                                        <div className='tableRow noBorder'>
                                                                                <div className='bold20'>2020.10</div>
                                                                                <div>2020 꿈꾸는 아이(AI)인공지능 솔루션 경진대회 </div>
                                                                                <div>1등</div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                                <div className='talentPicture'>
                                                        <img src={talent} className='' />
                                                </div>
                                                {/* <div className='row'>
                                        <img src={talent} className='talentPicture' />
                                        </div>
                                        <div className='row'>
                                        <div className='left'>
                                                        <div className='bold30 marginB10'>과학기술의 사회 공헌</div>
                                                        <div className='underLine'></div>
                                                        <div>인공지능의 미래와 현재에 대해 지역사회 세미나를 주기적으로 개최합니다.</div>
                                                        <div>중학교, 고등학교 재능 기부는 저희 ALI의 많은 업무 중에서도 중요한 활동입니다.</div>
                                                        <div>지역사회 발전과 인공지능에 대한 바른 이해를 돕기 위해</div>
                                                        <div>항상 노력하는 ALI 입니다.</div>
                                                </div>
                                             
                                        </div> */}
                                                <div className='bottomBorder' />
                                                <div className='row2'>
                                                {i18n.language=='ko'?
<>
                                                        <div className='bold40 marginB30'>'데이터 의존성'을 줄이는 딥러닝 학습 기술의 고도화</div>
                                                        <div>데이터 의존성을 줄이는 기술은</div>
                                                        <div>인공지능을 우리의 삶에 더욱 가까이 오게 합니다.</div>
                                                        <div>언제나, 누구나, 어디서나 ALI가 제공하는 인공지능 서비스를 누리는 날까지</div>
                                                        <div>저희는 연구를 멈추지 않습니다.</div>
                                                        </>
                                                        :
                                                        <>
                                                         <div className='bold40 marginB30'>Advancing deep learning Technologies</div>
                                                         <div className='bold40 marginB30'>that reduce 'data dependence'</div>
                                                        <div>Technologies that reduce data dependency It brings AI closer to our lives.</div>
                                                        <div>Until the day that anyone, anytime,</div>
                                                        <div>nywhere enjoys the artificial intelligence services provided by</div>
                                                        <div> ALI We don't stop the research.</div>
                                                        </>
}
                                                        <img src={about2} />
                                                        <div className='left'>

                                                        </div>
                                                        <div className='right'>
                                                        </div>
                                                </div>
                                                <div className='row2'>
                                                {i18n.language=='ko'?
<>
                                                        <div className='bold40 marginB30'>감동을 줄 수 있는 Human AI가 우리 눈앞의 현실로</div>
                                                        <div>ALI의 low data dependency와 NLP 기술의 융합으로</div>
                                                        <div>고객중심의 다양한 서비스를 개발하고 있습니다.</div>
                                                        <div>사용자의 마음을 이해하고, 감동을 줄 수 있는 인공지능서비스를 위해</div>
                                                        <div>끊임없이 노력하는 ALI입니다.</div>
                                                        </>
                                                        :
                                                        <>
                                                        <div className='bold40 marginB30'>A human AI that can touch us is a reality in front of our eyes</div>
                                                        <div>AThrough the convergence of ALI's low data dependency and NLP technology,</div>
                                                        <div>We are developing various customer-centered services.</div>
                                                        <div>For AI services that can understand and touch users' minds,</div>
                                                        <div> ALI is constantly working hard.</div>
                                                        </>
}

                                                        <img src={about3} />
                                                        <div className='left'>

                                                        </div>
                                                        <div className='right'>
                                                        </div>
                                                </div>
                                        </div>
                                        <div className='aboutUsBottom'>
                                                <div className='bottomBorder2' />
                                                {i18n.language=='ko'?

                                                <div className='bold40 marginB30'>ALI's 멤버</div>
                                                :
                                                <div className='bold40 marginB30'>ALI's members</div>
}
                                                <div className='row'>
                                                        <div className='colItem'>
                                                                <img src={lmh} className='picture' />
                                                                <div className='chief'>CEO</div>
                                                                <div className='text'>
                                                                        <div className='bold20'>이민호</div>
                                                                        <div>경북대학교 교수</div>
                                                                        <div>KAIST 박사</div>
                                                                        <div>인공신경망, 딥러닝</div>
                                                                </div>
                                                        </div>
                                                        <div className='colItem'>
                                                                <img src={lsy} className='picture' />
                                                                <div className='chief'>CTO</div>
                                                                <div className='text'>
                                                                        <div className='bold20'>이수영</div>
                                                                        <div>KAIST 명예교수</div>
                                                                        <div>인공신경망, 딥러닝</div>
                                                                        <div>NYU 박사</div>
                                                                </div>
                                                        </div>
                                                        <div className='colItem'>
                                                                <img src={jes} className='picture' />
                                                                <div className='chief'>CSO</div>
                                                                <div className='text'>
                                                                        <div className='bold20'>정은수</div>
                                                                        <div>KAIST 박사 </div>
                                                                        <div>삼성 SDS</div>
                                                                        <div>자연어처리, 딥러닝</div>
                                                                </div>
                                                        </div>

                                                </div>
                                                <div className='row'>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>최인철</div>
                                                                        <div>플로리다주립대 박사</div>
                                                                        <div>영상 및 언어처리</div>
                                                                        <div>딥러닝, 인공지능</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>최용화</div>
                                                                        <div>경북대학교 박사</div>
                                                                        <div>딥러닝</div>
                                                                        <div>컴퓨터비전</div>
                                                                        <div>자연어처리</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>박재석</div>
                                                                        <div>경북대학교</div>
                                                                        <div>인공지능학과 석사</div>
                                                                        <div>자연어처리</div>
                                                                        
                                                                </div>
                                                        </div>


                                                </div>
                                                <div className='row'>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>이청안</div>
                                                                        <div>KAIST 석사</div>
                                                                        <div>AI. Lab, CrossCert</div>
                                                                        <div>자연어처리, 강화학습</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>육동영</div>
                                                                        <div>금오공대</div>
                                                                        <div>전자공학 학사</div>
                                                                        <div>Frontend 프로그래머</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>장동현</div>
                                                                        <div>경북대</div>
                                                                        <div>인공지능학과 석사</div>
                                                                        <div>자연어처리</div>
                                                                        <div>Backend 프로그래머</div>
                                                                </div>
                                                        </div>


                                                </div>
                                                <div className='row'>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>송지현</div>
                                                                        <div>고려대 석사</div>
                                                                        <div>자연어처리, 딥러닝</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>Miika Toikkanen</div> 
                                                                        <div>경북대학교 석사</div>
                                                                        <div>딥러닝, 자연어처리</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>신웅재</div>
                                                                        <div>경북대학교 석사</div>
                                                                        <div>딥러닝, SI</div>
                                                                </div>
                                                        </div>


                                                </div>
                                                <div className='row'>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>신동호</div>
                                                                        <div>두잇파이브(Doit5)</div>
                                                                        <div>웹개발자</div>
                                                                        <div>우송대 학사</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>김예원</div>
                                                                        <div>카톨릭대 학사</div>
                                                                        <div>경영/재무/회계</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                        <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>박눈솔</div>
                                                                        <div>경북대학교 석사</div>
                                                                        <div>빅데이터 분석</div> 
                                                                        <div>자연어처리</div>
                                                                </div>
                                                        </div>


                                                </div>
                                        </div>
                                </div>
                        </MediaQuery>
                {/*모바일//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                        <MediaQuery maxWidth={1224}>
                                <div className='aboutUs'>
                                        <div className='aboutUsTop'>
                                                <Fade left cascade duration={2000} delay={200}><div className='bold20'>자연어처리 인공지능 전문 회사</div></Fade>
                                                <div className='bold40'><span style={{fontSize:45}}>(주)</span>ALI는</div>
                                                 <div className='bold40 marginB10'>어떤 회사인가요?</div>
                                                <div className='lineH f16'>
                                                        <br />
                                                        ALI는 데이터 의존성을 줄이는 딥러닝 학습 기술의 고도화를  목표로,
                                                        인공지능 업계의 새로운 패러다임을 선도하는 기술기반 기업입니다.
                                                </div>
                                        </div>
                                        <div className='bottomBorder' />
                                        <div className='aboutUsCenter'>

                                                 <div className='col'>
                                                 {/* <Fade top cascade duration={1500} > */}
                                                        <div className='bold35 marginB30 lineH'>모든 대화형 인공지능기술의 통합</div>
                                                       <div>ALI는 요약, 대화, 지식 등</div>
                                                       <div>모든 자연어 처리 분야에서 세계 최고를 지향합니다.</div>
                                                {/* </Fade> */}
                                                <img src={about1M} className='historyImage' />
                                                </div>
                                                 <div className='col'>
                                                        <div className='bold35 marginB30 lineH'>과학기술의 사회 공헌</div>
                                                        {/* <div className='underLine'></div> */}
                                                        인공지능의 미래와 현재에 대해 지역사회 세미나를<br />
                                                        주기적으로 개최합니다.<br /><br />
                                                        중학교, 고등학교 재능 기부는<br />
                                                        저희 ALI의 많은 업무 중에서도 중요한 활동입니다.<br /><br />
                                                        지역사회 발전과<br />
                                                          인공지능에 대한 바른 이해를 돕기 위해<br />
                                                        항상 노력하는 ALI입니다.<br />

                                                </div>
                                                <img src={talent} className='talentPicture' />
                                               <div className='tableWrap'>
                                                        <div className='tableRow'>
                                                                <div className='bold15'>2021.12</div>
                                                                <div>과학기술정보통신부장관 표창장</div>
                                                                <div>표창</div>
                                                        </div>
                                                        <div className='tableRow'>
                                                                <div className='bold15'>2021.12</div>
                                                                <div className='tableCenter'>과학기술정보통신부장관<br/> SW연구개발분야 우수상</div>
                                                                <div>포상</div>
                                                        </div>
                                                        <div className='tableRow'>
                                                                <div className='bold15'>2021.12</div>
                                                                <div>Sleep AI Challenge 대회</div>
                                                                <div>1등</div>
                                                        </div>
                                                        <div className='tableRow noBorder'>
                                                                <div className='bold15'>2020.10</div>
                                                                <div className='tableCenter'><div>2020 꿈꾸는 아이(AI)인공지능</div> 솔루션 경진대회 </div>
                                                                <div>1등</div>
                                                        </div>
                                                </div>
                                               
                                               
                                              
                                                <div className='bottomBorder' />
                                                <div className='col'>

                                                        <div className='bold25'>'데이터 의존성'을 줄이는</div><div className='bold25 marginB30'> 딥러닝 학습 기술의 고도화</div>
                                                        데이터 의존성을 줄이는 기술은<br />
                                                        인공지능을 우리의 삶에 더욱 가까이 오게 합니다.<br />
                                                        언제나, 누구나, 어디서나 <br />
                                                        ALI가 제공하는 인공지능 서비스를 누리는 날까지<br />
                                                        저희는 연구를 멈추지 않습니다.
                                                        <img src={about2} className='image' />
                                                        <div className='left'>

                                                        </div>
                                                        <div className='right'>
                                                        </div>
                                                </div>
                                                <div className='col'>

                                                        <div className='bold25'>감동을 줄 수 있는 Human AI가 </div><div className='bold25 marginB30'>우리 눈앞의 현실로</div>
                                                        ALI의 low data dependency와<br />
                                                        NLP 기술의 융합으로<br />
                                                        고객중심의 다양한 서비스를 개발하고 있습니다<br />
                                                        사용자의 마음을 이해하고<br />
                                                        감동을 줄 수 있는 인공지능서비스를 위해<br />
                                                        끊임없이 노력하는 ALI입니다.

                                                        <img src={about3} className='image' />
                                                        <div className='left'>

                                                        </div>
                                                        <div className='right'>
                                                        </div>
                                                </div> 
                                                <div className='bottomBorder2' />

                                        </div>
                                        <div className='aboutUsBottom'>

                                                <div className='bold40 marginB30'>ALI's 멤버</div>
                                                <div className='row'>
                                                        <div className='colItem'>
                                                                <img src={lmh} className='picture' />
                                                                <div className='chief'>CEO</div>
                                                                <div className='text'>
                                                                <div className='bold20'>이민호</div>
                                                                        <div>경북대학교 교수</div>
                                                                        <div>KAIST 박사</div>
                                                                        <div>인공신경망, 딥러닝</div>
                                                                </div>
                                                        </div>
                                                        <div className='colItem'>
                                                                <img src={lsy} className='picture' />
                                                                <div className='chief'>CTO</div>
                                                                <div className='text'>
                                                                        <div className='bold20'>이수영</div>
                                                                        <div>KAIST 명예교수</div>
                                                                        <div>인공신경망, 딥러닝</div>
                                                                        <div>NYU 박사</div>
                                                                </div>
                                                        </div>
                                                        <div className='colItem'>
                                                                <img src={jes} className='picture' />
                                                                <div className='chief'>CSO</div>
                                                                <div className='text'>
                                                                        <div className='bold20'>정은수</div>
                                                                        <div>KAIST 박사 </div>
                                                                        <div>삼성 SDS</div>
                                                                        <div>자연어처리, 딥러닝</div>
                                                                </div>
                                                        </div>

                                                </div>
                                                <div className='row'>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>최인철</div>
                                                                        <div>플로리다주립대 박사</div>
                                                                        <div>영상 및 언어처리</div>
                                                                        <div>딥러닝, 인공지능</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>최용화</div>
                                                                        <div>경북대학교 박사</div>
                                                                        <div>딥러닝</div>
                                                                        <div>컴퓨터비전</div>
                                                                        <div>자연어처리</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>박재석</div>
                                                                        <div>경북대학교</div>
                                                                        <div>인공지능학과 석사</div>
                                                                </div>
                                                        </div>


                                                </div>
                                                <div className='row'>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>이청안</div>
                                                                        <div>KAIST 석사</div>
                                                                        <div>AI. Lab, CrossCert</div>
                                                                        <div>자연어처리, 강화학습</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>육동영</div>
                                                                        <div>금오공대</div>
                                                                        <div>전자공학 학사</div>
                                                                        <div>Frontend 프로그래머</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>장동현</div>
                                                                        <div>경북대</div>
                                                                        <div>인공지능학과 석사</div>
                                                                        <div>자연어처리</div>
                                                                        <div>Backend 프로그래머</div>
                                                                </div>
                                                        </div>


                                                </div>
                                                <div className='row'>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>송지현</div>
                                                                        <div>고려대 석사</div>
                                                                        <div>자연어처리, 딥러닝</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>Miika Toikkanen</div>
                                                                        <div>경북대학교 석사</div>
                                                                        <div>딥러닝, 자연어처리</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>신웅재</div>
                                                                        <div>경북대학교 석사</div>
                                                                        <div>딥러닝, SI</div>
                                                                </div>
                                                        </div>


                                                </div>
                                                <div className='row'>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>신동호</div>
                                                                        <div>두잇파이브(Doit5)</div>
                                                                        <div>웹개발자</div>
                                                                        <div>우송대 학사</div>
                                                                </div>
                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                        Researcher
                                                                </div>
                                                                <div className='text'>
                                                                        <div className='bold20'>김예원</div>
                                                                        <div>카톨릭대 학사</div>
                                                                        <div>경영/재무/회계</div>
                                                                </div>

                                                        </div>
                                                        <div className='rowItem'>
                                                                <div className='circle' >
                                                                Researcher 
                                                                </div>
                                                                <div className='text'>
                                                                     
                                                                <div className='bold20'>박눈솔</div>
                                                                        <div>경북대학교 석사</div>
                                                                        <div>빅데이터 분석</div> 
                                                                        <div>자연어처리</div>
                                                                </div>
                                                        </div>


                                                </div>
                                        </div>
                                       
                                </div>
                        </MediaQuery>

                </AppLayout>
        )
}

AboutUs.propTypes = {};

export default AboutUs;