import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../components/AppLayout';
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useTrail, useSpring, animated } from 'react-spring'
import MediaQuery from 'react-responsive'


import image_1_7 from '../assets/image_1_7.png'
import image_1_8 from '../assets/image_1_8.png'

import image_3_1 from '../assets/image_3_1.png'
import image_3_2 from '../assets/image_3_2.png'
import image_3_3 from '../assets/image_3_3.png'
import image_3_4 from '../assets/image_3_4.png'
import image_3_5 from '../assets/image_3_5.png'
import image_3_6 from '../assets/image_3_6.png'
import image_3_7 from '../assets/image_3_7.png'
import partner from '../assets/partner.png'
import partnerM from '../assets/partnerM.png'

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { useTranslation } from "react-i18next";
const Products3 = () => {
        const demoLink = "https://gatch.watch"
        const { t, i18n } = useTranslation()


        return (
                <AppLayout>
                        <MediaQuery minWidth={1224}>
                                <div className="products">
                                        <div>
                                                <div className='productsTop'>
                                                        {i18n.language == 'ko' ?

                                                                <div className='textContents'>
                                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>메타버스 극장으로 초대합니다.</div></Fade>
                                                                        <div className='bold70 marginB10'>같이워치</div>

                                                                        <div className='text'>

                                                                                <div className='lineH f18'>메타버스 세상에서 함께 영상을 보는 같이워치</div>
                                                                                <a href={demoLink} target="_blank" className='demoButton3 demoButtonPosition'>demo 체험하기</a>
                                                                        </div>
                                                                </div>
                                                                :
                                                                <div className='textContents'>
                                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>Invite you to the Metaverse Theater.</div></Fade>
                                                                        <div className='bold70 marginB10'>Gachwatch</div>

                                                                        <div className='text'>

                                                                                <div className='lineH f18'>Watching videos together in the Metabus world</div>
                                                                                <a href={demoLink} target="_blank" className='demoButton3 demoButtonPosition'>demo</a>
                                                                        </div>
                                                                </div>
                                                        }
                                                </div>
                                        </div>
                                        <div className='productsCenter'>

                                                <div className='rowTop'>
                                                        <Fade top cascade duration={1500} >
                                                                {i18n.language == 'ko' ?
                                                                        <>
                                                                                <div className='bold30 marginB30'>좋아하는 영상을 함께 보면 감동이 두 배!</div>
                                                                                <div className='bold30 marginB30'>집에서 극장을 느끼세요.</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                                <div className='bold30 marginB30'>If you watch your favorite videos, you'll be impressed twice!</div>
                                                                                <div className='bold30 marginB30'>Feel the theater at home.</div>
                                                                        </>
                                                                }
                                                        </Fade>
                                                        <Fade>
                                                                <img className="itemImage" src={image_3_1} />
                                                        </Fade>
                                                </div>
                                                <div className='rowTop'>
                                                        <div className='left'>

                                                        </div>
                                                        <div className='right'>

                                                        </div>
                                                </div>
                                                <div className='bottomBorder' />
                                                <div className='row'>
                                                        <div className='left'>
                                                                {i18n.language == 'ko' ?
                                                                        <>
                                                                                <div className='bold30 marginB10 '>좋아하는 영상을 함께 보는 같이워치</div>
                                                                                <div className='underLine'></div>

                                                                                <div>같이워치 메타버스 세계에서 유튜브 영상을 다른 사람들과 함께 보며 얘기해보세요.</div>
                                                                                <div>내 아바타를 통해 영상을 보면서 느끼는 감정을 사람들과 공유하세요.</div>
                                                                                <div>같이워치에서 영상을 본다면,</div>
                                                                                <div>극장에서 혹은 집에서 친구, 애인과 함께 영상을 보듯이</div>
                                                                                <div> 생생한 현장감을 느끼게 될 거에요.</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                                <div className='bold30 marginB10 '>Watching their favorite videos together.</div>
                                                                                <div className='underLine'></div>

                                                                                <div>Let's watch YouTube videos on Watch Metaverse and talk with others. </div>
                                                                                <div>Share your feelings with people while watching videos through my avatar.</div>
                                                                                <div>If we watch the video together on the watch, At the theater or at home,</div>
                                                                                <div>you can watch videos with your friends and girlfriend.</div>
                                                                                <div>You'll feel a vivid sense of reality.</div>
                                                                        </>
                                                                }
                                                        </div>
                                                        <div className='right'>
                                                                <img className="itemImage" src={image_3_2} />
                                                        </div>
                                                </div>
                                                <div className='row'>
                                                        <div className='left'>
                                                                <img className="itemImage" src={image_3_3} />

                                                        </div>
                                                        <div className='right'>
                                                                {i18n.language == 'ko' ?
                                                                        <>
                                                                                <div className='bold30 marginB10 '>메타버스에 사는 나를 꾸며보세요.</div>
                                                                                <div className='underLine'></div>

                                                                                <div className=''>수천 가지 아이템으로 나만의 개성을 뽐내세요.</div>
                                                                                <div className=''>나이, 인종, 국적을 초월한 자신만의 아이덴티티를 가질 수 있어요.</div>
                                                                                <div className=''>평소 꿈꾸던 나의 모습, 이상형이 이젠 더는 상상이 아니에요.</div>
                                                                                <div className=''>메타버스에서 새로운 친구와 새로운 아이덴티티로 .</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                                <div className='bold30 marginB10 '>Decorate me living in Metaverse.</div>
                                                                                <div className='underLine'></div>

                                                                                <div className=''>Show off your individuality with thousands of items.</div>
                                                                                <div className=''>You can have your own identity beyond age, race, and nationality.</div>
                                                                                <div className=''> I can't imagine my ideal type anymore, my ideal type, anymore.</div>
                                                                                <div className=''> With new friends and new identities on Metaverse.</div>
                                                                        </>
                                                                }

                                                        </div>

                                                </div>
                                                <div className='row'>
                                                        <div className='left'>
                                                                {i18n.language == "ko" ?
                                                                        <>
                                                                                <div className='bold30 marginB10 '>전 세계 사람들과 감정을 실시간으로 공유하세요.</div>
                                                                                <div className='underLine'></div>
                                                                                <div className=''>영상, 채팅의 내용을 이해하여 감정을 인식합니다.</div>
                                                                                <div className=''>영상을 분석해서 인공지능이 실시간으로 감정을 표현해요.</div>
                                                                                <div className=''>나의 대화에 AI가 즉시 반응하여 감정을 인식하고 공감하거나 위로해주기도 합니다. </div>
                                                                                <div className=''>이젠 집에서 혼자 영화를 봐도 더는 외롭지 않아요.</div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                                <div className='bold30 marginB10 '>Share your feelings with people all over the world in real time.</div>
                                                                                <div className='underLine'></div>
                                                                                <div className=''>Recognize emotions by understanding the contents of video and chat. </div>
                                                                                <div className=''>By analyzing the video, artificial intelligence expresses emotions in real time.</div>
                                                                                <div className=''> AI immediately responds to my conversation, recognizing, empathizing, or comforting my feelings. </div>
                                                                                <div className=''>Even if I watch a movie alone at home, I don't feel lonely anymore.</div>
                                                                        </>
                                                                }

                                                        </div>
                                                        <div className='right'>
                                                                <img className="itemImage" src={image_3_4} />
                                                        </div>
                                                </div>
                                                <div className='bold40 marginB50'>Product Features</div>
                                                <div className='bottomRow'>
                                                {i18n.language == "ko" ?
                                                        <>
                                                       <div className='bottomCol'>
                                                                <img className="itemImage" src={image_3_5} />
                                                                <div className='bold20 marginB10'>Hierarchical GPT</div>
                                                                <div className='textWrap'>계층 구조를 활용한 신경망 모델을 통해 기존의 모델보다 훨씬 오래전의 문맥까지 기억하여 대화합니다. 같이워치에서 사용자가 AI 캐릭터를 생성할 수 있고 이 기술을 통해 AI 캐릭터는 사용자와 했던 모든 대화를 기억하고 대화에 활용할 수 있습니다. </div>

                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_3_6} />
                                                                <div className='bold20 marginB10'>Video Understanding GPT</div>
                                                                <div className='textWrap'>영상을 이해할 수 있는 모델을 통해 영상과 관련된 내용에 대해 대화를 합니다. 같이워치에서 생성된 AI 캐릭터와 같이 영상을 보며 관련된 대화를 할 수 있습니다. </div>
                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_3_7} />
                                                                <div className='bold20 marginB10'>Emotion Tagger</div>
                                                                <div className='textWrap'>영상과 사용자의 대화에서 감정을 인식합니다. 같이워치의 AI 캐릭터가 영상을 보며 감정을 먼저 표현하기도 하고 대화를 통해 사용자의 감정을 인식하고 공감하거나 위로해주기도 합니다.</div>
                                                        </div>
                                                        </>
                                                        :
                                                       
                                                        <>
                                                        <div className='bottomCol'>
                                                        <img className="itemImage" src={image_3_5} />
                                                        <div className='bold20 marginB10'>Hierarchical GPT</div>
                                                        <div className='textWrap'>Through the neural network model that utilizes the hierarchical structure, it memorizes the context much longer than the existing model and communicates. Together, users can create AI characters on Watch, and this technology allows AI characters to remember and use all conversations they have with users.</div>

                                                </div>
                                                <div className='bottomCol'>
                                                        <img className="itemImage" src={image_3_6} />
                                                        <div className='bold20 marginB10'>Video Understanding GPT</div>
                                                        <div className='textWrap'>A model that can understand the image has a conversation about the content related to the image. You can have a related conversation while watching a video like an AI character created on a watch.</div>
                                                </div>
                                                <div className='bottomCol'>
                                                        <img className="itemImage" src={image_3_7} />
                                                        <div className='bold20 marginB10'>Emotion Tagger</div>
                                                        <div className='textWrap'>Recognize emotions in images and user conversations. Together, the AI character of the watch expresses emotions first by watching the video, and recognizes, empathizes, or comforts the user's emotions through conversation.</div>
                                                </div>
                                                        </>
}
                                                </div>

                                                <div className='bottomBorder' />
                                                <div className='bottomEx'>
                                                        <div className='bold40 marginB10 '>Example</div>

                                                        {/* <img className="itemImage" src={image_1_8} /> */}
                                                        <div className='bottomBorder' />

                                                        <img src={partner} />
                                                {i18n.language == "ko" ?

                                                        <a href={demoLink} target="_blank" className='demoButton3 demoButtonPosition'>demo 체험하기</a>
                                                        :
                                                        <a href={demoLink} target="_blank" className='demoButton3 demoButtonPosition'>demo</a>
                                                }
                                                </div>
                                        </div>

                                </div>
                        </MediaQuery>
                        {/*모바일//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                        <MediaQuery maxWidth={1224}>
                                <div className="products">
                                        <div>
                                                <div className='productsTop'>
                                                        <div className='textContents'>
                                                                <Fade left cascade duration={2000} delay={200}><div className='bold20'>메타버스 극장으로 초대합니다.</div></Fade>
                                                                <div className='bold50 marginB10'>같이워치</div>


                                                                <div className='lineH f16'>메타버스 세상에서 함께 영상을 보는 같이워치</div>
                                                                <a href={demoLink} target="_blank" className='demoButton3 demoButtonPosition'>demo 체험하기</a>
                                                        </div>

                                                </div>
                                        </div>
                                        <div className='productsCenter'>
                                                <div className='bottomBorder' />

                                                <div className='col'>
                                                        <div className='bold30 marginB30'>좋아하는 영상을 함께 보면 감동이 두 배!</div>
                                                        <div className='bold30 marginB30'>집에서 극장을 느끼세요.</div>
                                                        <Fade>
                                                                <img className="itemImage" src={image_3_1} />
                                                        </Fade>
                                                </div>

                                                <div className='bottomBorder2' />
                                                <div className='col'>
                                                        <div className='bold30 marginB10 '>좋아하는 영상을 함께 보는 같이워치</div><br />

                                                        같이워치 메타버스 세계에서 유튜브 영상을 다른 사람들과 함께 보며 얘기해보세요.<br />
                                                        내 아바타를 통해 영상을 보면서 느끼는 감정을 사람들과 공유하세요.<br />
                                                        같이워치에서 영상을 본다면,<br />
                                                        극장에서 혹은 집에서 친구, 애인과 함께 영상을 보듯이<br />
                                                        생생한 현장감을 느끼게 될 거에요.
                                                        <img className="itemImage" src={image_3_2} />
                                                </div>
                                                <div className='col'>
                                                        <div className='bold30 marginB10 '>메타버스에 사는 나를 꾸며보세요.</div><br />
                                                        수 천가지 아이템으로 나만의 개성을 뽐내세요.<br />
                                                        나이, 인종, 국적을 초월한 자신만의 아이덴티티를 가질 수 있어요.<br />
                                                        평소 꿈꾸던 나의 모습, 이상형이 이젠 더는 상상이 아니에요.<br />
                                                        메타버스에서 새로운 친구와 새로운 아이덴티티로 .
                                                        <img className="itemImage" src={image_3_3} />

                                                </div>
                                                <div className='col'>
                                                        <div className='bold30 marginB10 '>전 세계 사람들과 감정을 실시간으로 공유하세요.</div><br />
                                                        영상, 채팅의 내용을 이해하여 감정을 인식합니다.<br />
                                                        영상을 분석해서 인공지능이 실시간으로 감정을 표현해요.<br />
                                                        나의 대화에 AI가 즉시 반응하여 감정을 인식하고 공감하거나 위로해주기도 합니다. <br />
                                                        이젠 집에서 혼자 영화를 봐도 더는 외롭지 않아요.
                                                        <img className="itemImage" src={image_3_4} />
                                                </div>
                                                <div className='bold40 marginB50'>Product Features</div>
                                                <div className='bottomRow'>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_3_5} />
                                                                <div className='bold20 marginB10'>Hierarchical GPT</div>
                                                                <div className='textWrap'>계층 구조를 활용한 신경망 모델을 통해 기존의 모델보다 훨씬 오래전의 문맥까지 기억하여 대화합니다. 같이워치에서 사용자가 AI 캐릭터를 생성할 수 있고 이 기술을 통해 AI 캐릭터는 사용자와 했던 모든 대화를 기억하고 대화에 활용할 수 있습니다. </div>

                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_3_6} />
                                                                <div className='bold20 marginB10'>Video Understanding GPT</div>
                                                                <div className='textWrap'>영상을 이해할 수 있는 모델을 통해 영상과 관련된 내용에 대해 대화를 합니다. 같이워치에서 생성된 AI 캐릭터와 같이 영상을 보며 관련된 대화를 할 수 있습니다. </div>
                                                        </div>
                                                        <div className='bottomCol'>
                                                                <img className="itemImage" src={image_3_7} />
                                                                <div className='bold20 marginB10'>Emotion Tagger</div>
                                                                <div className='textWrap'>영상과 사용자의 대화에서 감정을 인식합니다. 같이워치의 AI 캐릭터가 영상을 보며 감정을 먼저 표현하기도 하고 대화를 통해 사용자의 감정을 인식하고 공감하거나 위로해주기도 합니다.</div>
                                                        </div>
                                                </div>

                                                <div className='bottomBorder' />
                                                <div className='bottomEx'>
                                                        <div className='bold40 marginB10 '>Example</div>

                                                        {/* <img className="itemImage" src={image_1_8} /> */}
                                                        <div className='bottomBorder' />

                                                        <img className="itemImage" src={partnerM} />
                                                        <a href={demoLink} target="_blank" className='demoButton3 demoButtonPosition'>demo 체험하기</a>
                                                </div>
                                        </div>

                                </div>
                        </MediaQuery>

                </AppLayout>
        )
}

Products3.propTypes = {};

export default Products3;