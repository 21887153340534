import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import TopNavigation from './TopNavigation';
import Bottom from './Bottom';
import back1 from '../assets/topbackground4.png'
import back2 from '../assets/topbackground2.png'
import { Spring, useSpring, animated, useTransition, } from 'react-spring'
import MediaQuery from 'react-responsive'

let classStyle = ''
let pre = ''
const AppLayout = ({ children, }) => {

        const location = useLocation();

        if (location.state?.from == '/Products1')
                pre = 'pre1'
        else if (location.state?.from == '/Products2')
                pre = 'pre2'
        //        setPre('cur2')
        else if (location.state?.from == '/Products3')
                pre = 'pre3'
        //        setPre('cur3')
        else if (location.state?.from == '/AboutUs')
                pre = 'pre4'
        else if (location.state?.from == '/Vision')
                pre = 'pre5'
        else if (location.state?.from == '/Press')
                pre = 'pre6'

        //전값이 없을때(초기진입)
        if (!location.state) {
                if (location.pathname == '/Products1' || location.pathname == '/') {
                        classStyle = `appLayout cur1 pre1`
                }
                else if (location.pathname == '/Products2') {
                        classStyle = `appLayout cur2 pre2`
                }
                else if (location.pathname == '/Products3') {
                        classStyle = `appLayout cur3 pre3`
                }
                else if (location.pathname == '/AboutUs') {
                        classStyle = `appLayout cur4 pre4`
                }
                else if (location.pathname == '/Vision') {
                        classStyle = `appLayout cur5 pre5`
                }
                else if (location.pathname == '/Press') {
                        classStyle = `appLayout cur6 pre6`
                }
        }
        else {

                if (location.pathname == '/Products1') {
                        classStyle = `appLayout cur1 ${pre}`
                }
                else if (location.pathname == '/Products2') {
                        classStyle = `appLayout cur2 ${pre}`
                }

                else if (location.pathname == '/Products3') {
                        classStyle = `appLayout cur3 ${pre}`
                }
                else if (location.pathname == '/AboutUs')
                        classStyle = `appLayout cur4 ${pre}`
                else if (location.pathname == '/Vision')
                        classStyle = `appLayout cur5 ${pre}`
                else if (location.pathname == '/Press')
                        classStyle = `appLayout cur6 ${pre}`
                else
                        classStyle = `appLayout cur1 ${pre}`
        }


        const style = useSpring({
                from: {
                        backgroundImage: `url(${back1})`,
                        transition: 2,
                        // opacity:0
                },
                to: {
                        backgroundImage: `url(${back2})`,
                        transition: 2,
                        // opacity:1
                },
                // delay:2000,
                config: { duration: 2000 },
        })
        return (
                <>
                        {/* <div className='appLayout'> */}
                                <div style={style} className={classStyle} >
                                        
                                        <TopNavigation />
                                        {/* <div>aaaa</div> */}
                                        <div className='centerContents'>
                                                {children}
                                        </div>
                                        <Bottom />
                                        <div className='preload1'></div>
                                        <div className='preload2'></div>
                                        <div className='preload3'></div>
                                        <div className='preload4'></div>
                                        <div className='preload5'></div>
                                        <div className='preload6'></div>
                                </div>
                    
                </>
        )

}

AppLayout.propTypes = {
        children: PropTypes.node.isRequired,
};

export default AppLayout;