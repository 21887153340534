import logo from './logo.svg';
import './App.css';
import { BrowserRouter , Route, Routes,  } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import './css/index.scss'
import Home from './page/Home';
import AboutUs from './page/AboutUs';
import Products1 from './page/Products1';
import Products2 from './page/Products2';
import Products3 from './page/Products3';
import Vision from './page/Vision';
import Press from './page/Press';
import { useMediaQuery, Medi } from 'react-responsive'

function App() {
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
return (
    <BrowserRouter>
      <Routes>
      <Route path="/" exact element={<Products1/>} />
      <Route path="/Home" exact element={<Home/>} />
      <Route path="/AboutUs" exact element={<AboutUs/>} />
      <Route path="/Products1" exact element={<Products1/>} />
      <Route path="/Products2" exact element={<Products2/>} />
      <Route path="/Products3" exact element={<Products3/>} />
      <Route path="/Vision" exact element={<Vision/>} />
      <Route path="/Press" exact element={<Press/>} />
      {/* <Route index element={<Home />} /> */}
      </Routes>
     
      </BrowserRouter>
  );
}

export default App;
