import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types'
import { useSpring, animated } from 'react-spring'
import { Link } from 'react-router-dom';
import roundA from '../assets/roundA.png'
import roundT from '../assets/roundT.png'
import roundG from '../assets/roundG.png'
import logo from '../assets/ALI_logo.png'
import MediaQuery from 'react-responsive'
import { useTranslation } from "react-i18next";
const Bottom = ({ }) => {
        const location = useLocation();
        const { t,i18n } = useTranslation()

        return (
                <div className='bottom'>
                        <MediaQuery minWidth={1224}>
                        {i18n.language=='ko'?
                        <div className='buttonWrap'>
                        
                        <Link className='pMenuButton' to='/aboutUs' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}> <img src={logo} /></Link>
                        <Link className='pMenuButton' to='/aboutUs' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>회사소개</Link>
                        <Link className='pMenuButton' to='/Press' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>보도매체</Link>
                        <Link className='pMenuButton' to='/Vision' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>비전</Link>
                        <Link className='pMenuButton' to='/Products1' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundA}/>&nbsp;&nbsp;&nbsp;AskMe</Link>
                        <Link className='pMenuButton' to='/Products2' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundT}/>&nbsp;&nbsp;&nbsp;티키타카</Link>
                        <Link className='pMenuButton' to='/Products3' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundG}/>&nbsp;&nbsp;&nbsp;같이워치</Link>
                        </div>
     :
     <div className='buttonWrap'>
                        
     <Link className='pMenuButton' to='/aboutUs' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}> <img src={logo} /></Link>
     <Link className='pMenuButton' to='/aboutUs' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>AboutUs</Link>
     <Link className='pMenuButton' to='/Press' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>Press</Link>
     <Link className='pMenuButton' to='/Vision' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>Vision</Link>
     <Link className='pMenuButton' to='/Products1' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundA}/>&nbsp;&nbsp;&nbsp;AskMe</Link>
     <Link className='pMenuButton' to='/Products2' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundT}/>&nbsp;&nbsp;&nbsp;Tiquitaca</Link>
     <Link className='pMenuButton' to='/Products3' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundG}/>&nbsp;&nbsp;&nbsp;Gachwatch</Link>
     </div>

}
                        <div>대구광역시 북구 대학로 80&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|</span>&nbsp;&nbsp;&nbsp;대표 : 이민호&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|</span>&nbsp;&nbsp;&nbsp;사업자등록번호 : 584-88-01223 </div>
                        <div>문의 : mholee@gmail.com&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|</span>&nbsp;&nbsp;&nbsp;전화 : 010-8859-6436 </div>
                        <div>© 2020 ALI Corp. All rights reserved.</div>
                        </MediaQuery>




                        <MediaQuery maxWidth={1224}>
                        <div className='buttonWrap'>
                                <div className='buttonItem'>
                        <Link className='pMenuButton' to='/aboutUs' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}> 홈&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|&nbsp;&nbsp;&nbsp;</span></Link>
                        <Link className='pMenuButton' to='/aboutUs' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>회사소개&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|&nbsp;&nbsp;&nbsp;</span></Link>
                        <Link className='pMenuButton' to='/Press' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>보도매체&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|&nbsp;&nbsp;&nbsp;</span></Link>
                        <Link className='pMenuButton' to='/Vision' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}>비전&nbsp;&nbsp;&nbsp;</Link>
                        </div>
                        <div className='buttonItem'>
                        <Link className='pMenuButton' to='/Products1' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundA}/>&nbsp;&nbsp;&nbsp;AskMe&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|&nbsp;&nbsp;&nbsp;</span></Link>
                        <Link className='pMenuButton' to='/Products2' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundT}/>&nbsp;&nbsp;&nbsp;티키타카&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|&nbsp;&nbsp;&nbsp;</span></Link>
                        <Link className='pMenuButton' to='/Products3' state={{from:location.pathname}} style={{display:'flex',alignItems:'center', color: '#fff',textDecoration: 'none'}}><img src={roundG}/>&nbsp;&nbsp;&nbsp;같이워치</Link>
                        </div>
                        </div>
                        <div>대구광역시 북구 대학로 80&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|</span>&nbsp;&nbsp;&nbsp;대표 : 이민호&nbsp;&nbsp;&nbsp;</div>
                        <div>사업자등록번호 :584-88-01223 </div>
                        <div>문의 : mholee@gmail.com&nbsp;&nbsp;&nbsp;<span style={{fontSize:12}}>|</span>&nbsp;&nbsp;&nbsp;전화 : 010-8859-6436 </div>
                        <div>© 2020 ALI Corp. All rights reserved.</div>
                        </MediaQuery>
                   
                                    {/* <div>CEO. MINHO LEE TEL. 82-10-8859-6436 E-mail: mholee@gmail.com</div>
<div>80, Daehak-ro, Buk-go, Daegu, Republic of Korea</div>
<div>© 2020 ALI Corp. All rights reserved.</div> */}
                </div>

        )

}

Bottom.propTypes = {};

export default Bottom;