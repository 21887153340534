import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../components/AppLayout';
import about1 from '../assets/about1.png'
import about2 from '../assets/about2.png'
import about3 from '../assets/about3.png'
import lmh from '../assets/lmh.png'
import lsy from '../assets/lsy.png'
import jes from '../assets/jes.png'
import talent from '../assets/talent.jpg'
import vision1 from '../assets/vision1.png'
import vision2 from '../assets/vision2.png'
import vision3 from '../assets/vision3.png'
import visionImage1 from '../assets/visionImage1.png'
import visionImage2 from '../assets/visionImage2.png'
import Fade from 'react-reveal/Fade';
import { useSpring, animated, useTransition, config, Transition } from 'react-spring'
import MediaQuery from 'react-responsive'
import { useTranslation } from "react-i18next";

const Vision = () => {
        // const styles = useSpring({

        //         from: { opacity: 1 },
        //         enter: { opacity: 0 },
        //         leave: { opacity: 0 },
        //         delay: 2000,
        //         config: config.molasses,
        //  })
    const { t,i18n } = useTranslation()
    return (
                <AppLayout>
                        <MediaQuery minWidth={1224}>
                                <div className='vision'>
                                        <div className='visionTop'>
                                                <div>
                                                        {i18n.language == 'ko' ?
                                                        <>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>무엇이든지 답변해 주는 감성 AI 엔진</div></Fade>
                                                        <div className='bold50 marginB10'><span style={{fontSize:45}}>(주)</span>ALI의 미래와 꿈</div>
                                                        </>
                                                        :
                                                        <>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>Emotional engine that responds to anything.</div></Fade>
                                                        <div className='bold50 marginB10'>ALI's Future and Dreams</div>
                                                        </>
    }

                                                </div>

                                        </div>

                                        <div className='visionCenter'>

                                                {/* <img src={image_1_1} className='image1' /> */}
                                                {/* <Fade duration={1500}>
                                                <div className='colItem'>
                                                        <img src={lmh} className='picture' />
                                                        <div className='chief'>CEO</div>
                                                </div>
                                        </Fade>
                                        <div className='textWrap'>
                                                <div className='textContent'>
                                                        국회의원은 국회에서 직무상 행한 발언과 표결에 관하여 국회외에서 책임을 지지 아니한다. 모든 국민은 고문을 받지 아니하며, 형사상 자기에게 불리한 진술을 강요당하지 아니한다.

                                                        광물 기타 중요한 지하자원·수산자원·수력과 경제상 이용할 수 있는 자연력은 법률이 정하는 바에 의하여 일정한 기간 그 채취·개발 또는 이용을 특허할 수 있다.

                                                        대통령·국무총리·국무위원·행정각부의 장·헌법재판소 재판관·법관·중앙선거관리위원회 위원·감사원장·감사위원 기타 법률이 정한 공무원이 그 직무집행에 있어서 헌법이나 법률을 위배한 때에는 국회는 탄핵의 소추를 의결할 수 있다.

                                                        근로자는 근로조건의 향상을 위하여 자주적인 단결권·단체교섭권 및 단체행동권을 가진다. 형사피해자는 법률이 정하는 바에 의하여 당해 사건의 재판절차에서 진술할 수 있다.
                                                </div>
                                                <div className='sign'>대표 이민호 올림</div>
                                        </div> */}
                                                {/* <div className='bottomBorder' /> */}
                                                <div className='row2'>
                                                {i18n.language == 'ko' ?
<>
                                                        <div className='bold40 marginB30'>사람을 움직이는 따뜻한 감성 AI</div>
                                                        <div className='text'>
                                                                차갑고 먼 인공지능이 아니라,<br />
                                                                삶 깊숙이 나와 관계를 맺으며, 동반자가 될 수 있는 <br />
                                                                따뜻한 인공지능의 세상을 만듭니다.<br />
                                                                학업, 일, 육아 등 지친 삶에서 함께 웃고 떠들 수 있는 인공지능이 얼마 남지 않았습니다.<br />
                                                        </div>
                                                        </>
                                                        :
<>
                                                        <div className='bold40 marginB30'>AI, warm emotions</div>
                                                        <div className='text'>
                                                        It's not cold, far-off artificial intelligence.<br />
                                                        It's a place where you can have a deep relationship with me and be a partner. <br />
                                                        It creates a warm world of artificial intelligence.<br />
                                                        There is not much artificial intelligence left to laugh and chat together in tired lives such as study, work, and childcare.<br />
                                                        </div>
                                                        </>
}
                                                        <img src={vision1} className='' />

                                                </div>
                                                <div className='bottomBorder2' />
                                                <div className='row2'>
                                                {i18n.language == 'ko' ?
<>
                                                        <div className='bold40 marginB30'>세계를 넘어 인류로</div>
                                                        <div className='text'>
                                                                세계로 뻗어 나가는 매출 그 이상을 넘어서서, <br />
                                                                환경, 사회, 인권에 이르기까지의 넓은 목표를 추구하고, 지속가능성을 높입니다.<br />
                                                                저희 ALI는 탄소 중립에 앞장서며, 모든 구성원을 서로 존중하며,<br />
                                                                사회발전에 이바지하고 있습니다.

                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                        <div className='bold40 marginB30'>beyond the world to mankind</div>
                                                        <div className='text'>
                                                        Beyond global sales, Pursuing broad objectives ranging from environmental,<br />
                                                        social and human rights, and increasing sustainability.<br />
                                                        We, ALI, are at the forefront of carbon neutrality,<br />
                                                        we respect all our members. It is contributing to the development of society.

                                                        </div>
                                                        </>
}
                                                        <img src={vision2} className='' />

                                                </div>
                                                <div className='bottomBorder2' />
                                                <div className='row2'>

                                                        <div className='bold40 marginB30'>Digital companion</div>
                                                {i18n.language == 'ko' ?
                                                        
                                                        <div className='text'>
                                                        생각: 변수에 대한 분석을 인간보다 빠르게 진행 <br />
                                                        성격: 자신만의 특징으로 반응하고 표현 <br />
                                                        언어: 생각과 감성을 언어로 표현 <br />
                                                        Digital companion을 구현하는 과정에서 개발되는 각 기능들을 필요로 하는 영역에 각각의<br />
Service로 공급, Deep learning 기반 인공지능의 한계를 극복하는 AI 솔루션을 개발<br />
                                                        </div>
                                                        :
                                                        <div className='text'>
                                                        Thinking: Analyzing variables faster than humans <br />
                                                        Personality: Respond to and express your own characteristics <br />
                                                        Language: Expressing thoughts and emotions in language<br />
                                                        In the process of implementing Digital Compensation,<br />
                                                        each area that requires each function developed Supply as a service, <br />
                                                        developing AI solutions that overcome the limitations of deep learning-based artificial intelligence.<br/>
                                                        </div>
}
                                                        <img src={vision3} className='' />

                                                </div>
                                                <div className='bottomBorder2' />
                                                <div className='visionBottom'>
                                                        <div className='left'>
                                                {i18n.language == 'ko' ?
                                                                <>
                                                                <div className='bold30 marginB30'>사랑과 협동만이 세상을 바꿉니다.</div>
                                                                건전하고 부드러운 조직문화를 추구하여, <br />
                                                                구성원들 모두의 창의성과 순발력을 발휘할 수 있게 모두가 노력합니다.<br />
                                                                ALI는 서로가 서로에게 힘이 되고,<br />
                                                                같이 성장할 수 있는 환경을 만드는데 앞장섭니다.<br />
                                                                세상의 변화는 작은 것에서부터 시작합니다.<br />
                                                                </>
                                                                :
                                                                <>
                                                                <div className='bold30 marginB30'>Only love and cooperation<br/> change the world.</div>
                                                                Pursuing a healthy and soft organizational culture,<br />
                                                                Everyone strives to demonstrate the creativity<br/>
                                                                 and agility of all the members.<br />
                                                                ALI supports each other.<br />
                                                                We take the lead in creating an environment<br/>
                                                                 where we can grow together.<br />
                                                                Changes in the world start with small things.<br />
                                                                </>
}

                                                        </div>
                                                        <div className='right'>
                                                                <img src={visionImage1} className='visionImage1' />
                                                                <img src={visionImage2} className='visionImage2' />
                                                        </div>

                                                </div>
                                        </div>
                                </div>
                        </MediaQuery>
                        {/*모바일//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                        <MediaQuery maxWidth={1224}>
                                <div className='vision'>
                                        <div className='visionTop'>
                                                <div>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold20'>NLP 전문 인공지능 회사</div></Fade>
                                                        <div className='bold40 marginB10'>(주)ALI의 미래와 꿈</div>

                                                </div>

                                        </div>

                                        <div className='visionCenter'>



                                                <div className='bottomBorder' />
                                                <div className='col'>

                                                        <div className='bold30 marginB30'>사람을 움직이는 따뜻한 감성 AI</div>
                                                        차갑고 먼 인공지능이 아니라,<br />
                                                        삶 깊숙이 나와 관계를 맺으며, 동반자가 될 수 있는 <br />
                                                        따뜻한 인공지능의 세상을 만듭니다.<br />
                                                        학업, 일, 육아 등 지친 삶에서 함께 웃고 떠들 수 있는 인공지능이 얼마 남지 않았습니다.<br />
                                                        <img className='itemImage' src={vision1} />

                                                </div>
                                                <div className='col'>

                                                        <div className='bold30 marginB30'>세계를 넘어 인류로</div>
                                                        세계로 뻗어 나가는 매출 그 이상을 넘어서서, <br />
                                                        환경, 사회, 인권에 이르기까지의 넓은 목표를 추구하고, 지속가능성을 높입니다.<br />
                                                        저희 ALI는 탄소 중립에 앞장서며, 모든 구성원을 서로 존중하며,<br />
                                                        사회발전에 이바지하고 있습니다.
                                                        <img className='itemImage' src={vision2} />

                                                </div>
                                                <div className='col'>

<div className='bold30 marginB30'>Digital companion</div>
생각: 변수에 대한 분석을 인간보다 빠르게 진행 <br />
                                                        성격: 자신만의 특징으로 반응하고 표현 <br />
                                                        언어: 생각과 감성을 언어로 표현 <br />
                                                        Digital companion을 구현하는 과정에서 개발되는 각 기능들을 필요로 하는 영역에 각각의<br />
Service로 공급, Deep learning 기반 인공지능의 한계를 극복하는 AI 솔루션을 개발<br />
<img className='itemImage' src={vision3} />

</div>
                                                <div className='bottomBorder2' />
                                                <div className='visionBottom'>
                                                        <div className='bold30 marginB30'>사랑과 협동만이 세상을 바꿉니다.</div>
                                                        건전하고 부드러운 조직문화를 추구하여, <br />
                                                        구성원들 모두의 창의성과 순발력을 발휘할 수 있게 모두가 노력합니다.<br />
                                                        ALI는 서로가 서로에게 힘이 되고,<br />
                                                        같이 성장할 수 있는 환경을 만드는데 앞장섭니다.<br />
                                                        세상의 변화는 작은 것에서부터 시작합니다.<br />

                                                        <img src={visionImage1} className='visionImage1' />

                                                </div>
                                        </div>
                                </div>
                        </MediaQuery>
                </AppLayout>
        )
}

Vision.propTypes = {};

export default Vision;
