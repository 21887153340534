import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../components/AppLayout';
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useTrail, useSpring, animated } from 'react-spring'
import image_1_1 from '../assets/image_1_1.png'
import image_1_2 from '../assets/image_1_2.png'
import image_1_3 from '../assets/image_1_3.png'
import image_1_4_3 from '../assets/image_1_4_3.png'
import image_1_5 from '../assets/image_1_5.png'
import image_1_6 from '../assets/image_1_6.png'
import image_1_7 from '../assets/image_1_7.png'
import image_1_8 from '../assets/image_1_8.png'
import partner from '../assets/partner.png'
import partnerM from '../assets/partnerM.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import MediaQuery from 'react-responsive'
import { useTranslation } from "react-i18next";
const Products1 = () => {
    const { t,i18n } = useTranslation()
    const demoLink="https://askme-plus.web.app"

return (
                <AppLayout>
                        <MediaQuery minWidth={1224}>
                        <div className="products">
                                <div>
                                        <div className='productsTop'>
                                                <div className='textContents'>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>{t('product1SubTopTitle')}</div></Fade>
                                                        <div className='bold70 marginB10'>AskMe</div>
                                                        {i18n.language=='ko'?
                                                        <>
                                                        <div className='lineH f18'>계약서, 보험약정서, 회계 등의 어려운 문서를 직접 읽으시나요?</div>
                                                        <div className='lineH f18'>이젠 고민하지 마세요.</div>
                                                        <div className='lineH f18'>30초만 주세요. AI가 대신 공부해서 알려드립니다.</div>
                                                        <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo 체험하기</a>
                                                        <a href={"https://neoali.notion.site/AskMe-Landing-Pages-a2396890886d486e9481a334a59d79de"} target="_blank" className='demoButton demoButtonPosition'>분야별 특화 모델 소개와 데모</a>
                                                        </>
                                                        :
                                                        <>
                                                        <div className='lineH f18'> </div>
                                                        <div className='lineH f18'>Do you read difficult documents such as contracts, insurance agreements, and accounting?</div>
                                                        <div className='lineH f18'>Don't worry now.</div>
                                                        <div className='lineH f18'>Give me 30 seconds. AI studies instead and tells you.</div>
                                                        <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo</a>
                                                        <a href={"https://neoali.notion.site/AskMe-Landing-Pages-a2396890886d486e9481a334a59d79de"} target="_blank" className='demoButton demoButtonPosition'>Introduction and demonstration of specialized models by field</a>
                                                        </>
                                                }
                                                        
                                                </div>

                                        </div>
                                </div>
                                <div className='productsCenter'>

                                        <div className='rowTop'>
                                                <Fade top cascade duration={1500} >
                                                        <div className='bold50 marginB30'>Plug Doc & Just AskMe!</div>
                                                        {i18n.language=='ko'?
                                                        <div className='f20 marginB30'>어려운 문서를 입력하면, 인공지능이 이해하여 답변합니다.</div>
                                                        :
                                                        <div className='f20 marginB30'>When you enter a difficult document, AI understands and answers it.
                                                        </div>
                                        }
                                                </Fade>
                                                <Fade>
                                                        <img className="itemImage" src={image_1_4_3} />
                                                </Fade>
                                        </div>
                                        <div className='rowTop'>
                                                <div className='left'>

                                                </div>
                                                <div className='right'>

                                                </div>
                                        </div>
                                        <div className='bottomBorder' />
                                        <div className='row'>
                                                <div className='left'>
                                                        {i18n.language=='ko'?
                                                        <div className='bold30 marginB10'>문서를 입력하세요.</div>
                                                        :
                                                        <div className='bold30 marginB10'>Please enter a document.</div>
                                }
                                                        <div className='underLine'></div>
                                                        {i18n.language=='ko'?
                                                        <>
                                                        <div>Action perception cycling 원리로 자체개발된</div>
                                                        <div>AI 엔진이 30초 만에 해당 문서를 직접 학습합니다.</div>
                                                        <div>표, 그래프, 대화형 문서 등 다양한 종류의 데이터를 즉시 체계화하여</div>
                                                        <div>사용자 물음에 답변을 준비합니다.</div>
                                                        </>
                                                        :
                                                        <>
                                                        <div>Action Perception Cycling Principle The AI engine learns the document directly in 30 seconds.</div>
                                                        <div> Immediate systematization of various types of data such as tables,</div>
                                                        <div>graphs, and interactive documents Prepare your</div>
                                                        <div>answers to user questions.</div>
                                                        </>
                        }
                                                </div>
                                                <div className='right'>
                                                        <img className="itemImage" src={image_1_1} />
                                                </div>
                                        </div>
                                        <div className='row'>
                                                <div className='left'>
                                                        <img className="itemImage" src={image_1_2} />

                                                </div>
                                                <div className='right'>
                                                {i18n.language=='ko'?
                                                <>
                                                        <div className='bold30 marginB10 '>무엇이든 물어보세요.</div>
                                                        <div className='underLine'></div>
                                                        <div>자연스러운 구어체로 무엇이든 물어보세요.</div>
                                                        <div>입력하신 문서의 내용을 바탕으로 사용자의 궁금증에</div>
                                                        <div>명쾌한 답변을 드립니다.</div>
                                                        <div>다년간 축적된 NLP 기술로 자연스럽고 정확한 문장으로 답변합니다.</div>
                                                        </>
                                                        :
                                                        <>
                                                        <div className='bold30 marginB10 '>Ask me anything.</div>
                                                        <div className='underLine'></div>
                                                        <div>Ask me anything in a natural colloquial way.</div>
                                                        <div>Based on the content of the document you entered, </div>
                                                        <div> we will answer your questions I give you a clear answer.</div>
                                                        <div>Answer in natural and accurate sentences with NLP technology</div>
                                                        <div>accumulated over the years.</div>
                                                        </>

                }

                                                </div>

                                        </div>

                                        <div className='row'>
                                                <div className='left'>
                                                {i18n.language=='ko'?
                                                <>
                                                        <div className='bold30 marginB10 '>자체 웹 검색 엔진 지식서비스</div>
                                                        <div className='underLine'></div>
                                                        <div className=''>문서에 없는 내용은 웹에서 학습하여 답변하는 융통성까지!</div>
                                                        <div className=''>세상의 모든 지식을 AI data integration system으로 최적화하여,</div>
                                                        <div className=''>해당 정보를 사용자의 요구에 맞게 답변합니다.</div>
                                                        </>
                                                        :
<>
                                                        <div className='bold30 marginB10 '>web search engine knowledge service</div>
                                                        <div className='underLine'></div>
                                                        <div className=''>What is not in the document is the flexibility to learn and answer on the web!</div>
                                                        <div className=''>By optimizing all knowledge in the world with AI data integration system,</div>
                                                        <div className=''>Answer the information to meet your needs.</div>
                                                        </>
                                                        }
                                                </div>
                                                <div className='right'>
                                                        <img className="itemImage" src={image_1_3} />
                                                </div>
                                        </div>
                                        <div className='bold40 marginB50'>Product Features</div>
                                        <div className='bottomRow'>
                                                <div className='bottomCol'>
                                                        <img className="itemImage" src={image_1_5} />
                                                        <div className='bold20 marginB10'>Action perception cycling</div>
                                                {i18n.language=='ko'?
                                                        <div className='textWrap'>사람이 지식을 습득하는 것과 같은 원리를 AI에 적용합니다. AI 스스로 질문과 답변을 하며, 단순 텍스트에 불과한 방대한 문서를 지식으로서 가치 있는 텍스트로 승격시킵니다.</div>
                                                        :
                                                        <div className='textWrap'>Apply the same principles to AI as humans acquire knowledge. AI answers questions and answers on its own, and promotes vast documents that are only simple text to valuable text as knowledge.
                                                        </div>

                                                }

                                                </div>
                                                <div className='bottomCol'>
                                                        <img className="itemImage" src={image_1_6} />
                                                        <div className='bold20 marginB10'>Machine reading comprehension</div>
                                                {i18n.language=='ko'?
                                                        <div className='textWrap'>기존의 인공지능 학습이란 수 많은 라벨링된 데이터가 필요합니다. 하지만 AskMe는 라벨링된 데이터가 없이, 스스로 학습하는 알고리즘을 사용합니다. 이로서 언제 어디서든, 30초내외로 문서를 학습 할 수 있습니다.</div>
                                                        :
                                                        <div className='textWrap'>Traditional AI learning requires a lot of labeled data. However, AskMe uses self-learning algorithms without labeled data. This allows you to learn documents anytime, anywhere, in 30 seconds or less.</div>
                                        }
                                                </div>
                                                <div className='bottomCol'>
                                                        <img className="itemImage" src={image_1_7} />
                                                        <div className='bold20 marginB10'>Unlimited applications</div>
                                                {i18n.language=='ko'?
                                                        <div className='textWrap'>학습데이터가 필요 없기 때문에, 응용 분야 또한 제약이 없습니다. 금융, 세금, 회계, 의료, 법률 등의 모든 분야에서 활약을 기대합니다.</div>
                                                        :
                                                        <div className='textWrap'>Because there is no restrictions, there is no restrictions in applications.Expectations are expected to performance in all fields such as financial, tax, accounting, and legal.</div>
                                }
                                                </div>
                                        </div>

                                        <div className='bottomBorder' />
                                        <div className='bottomEx'>
                                                <div className='bold40 marginB10 '>Example</div>

                                                <img className="itemImage" src={image_1_8} />

                                                <div className='bottomBorder' />

                                                <img src={partner} />

                                                <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>{t("demo")}</a>
                                                <a href={"https://neoali.notion.site/AskMe-Landing-Pages-a2396890886d486e9481a334a59d79de"} target="_blank" className='demoButton demoButtonPosition'>분야별 특화 모델 소개와 데모</a>
                                        </div>
                                </div>

                        </div>
                        </MediaQuery>
                {/*모바일//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                        <MediaQuery maxWidth={1224}>
                        <div className="products">
                                <div>
                                        <div className='productsTop'>
                                                <div className='textContents'>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold20'>인공지능이 대신 공부해서 알려 드립니다.</div></Fade>
                                                        <div className='bold50 marginB10'>AskMe</div>
                                                        <div className='text'>

                                                        <div className='lineH f16'>계약서, 보험약정서, 회계 등의 어려운 문서를 직접 읽으시나요?</div>
                                                        <div className='lineH f16'>이젠 고민하지 마세요.</div>
                                                        <div className='lineH f16'>30초만 주세요. AI가 대신 공부해서 알려 드립니다.</div>
                                                </div>
                                                <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo 체험하기</a>
                                                <a href={"https://neoali.notion.site/AskMe-Landing-Pages-a2396890886d486e9481a334a59d79de"} target="_blank" className='demoButton demoButtonPosition'>분야별 특화 모델 소개와 데모</a>
                                                </div>

                                        </div>
                                </div>
                                <div className='productsCenter'>
                                        <div className='bottomBorder'/>
                                        <div className='col'>
                                                        <div className='bold25 marginB30'>Plug Doc & Just AskMe!</div>
                                                        <div>어려운 문서를 입력하면,인공지능이 이해하여 답변합니다.</div>
                                                <Fade>
                                                        <img className="itemImage" src={image_1_4_3} />
                                                </Fade>
                                        </div>
                                       
                                        <div className='bottomBorder2' />
                                        <div className='col'>
                                                        <div className='bold30 marginB10'>문서를 입력하세요.</div><br/>
                                                        Action perception cycling원리로<br/>
                                                        자체개발된 AI 엔진이<br/>
                                                        30초 만에 해당 문서를 직접 학습합니다.<br/>
                                                        표, 그래프, 대화형 문서 등 다양한 종류의 데이터를 즉시 체계화하여
                                                        사용자 물음에 답변을 준비합니다.
                                                        <img className="itemImage" src={image_1_1} />
                                        </div>
                                        <div className='col'>

                                                        <div className='bold30 marginB10 '>무엇이든 물어보세요.</div><br/>
                                                        자연스러운 구어체로 무엇이든 물어보세요.<br/>
                                                        입력하신 문서의 내용을 바탕으로 사용자의 궁금증에<br/>
                                                        명쾌한 답변을 드립니다.<br/>
                                                        다년간 축적된 NLP 기술로 자연스럽고 정확한 문장으로 답변합니다.
                                                        <img className="itemImage" src={image_1_2} />

                                        </div>

                                        <div className='col'>
                                                        <div className='bold30 marginB10 '>자체 웹 검색 엔진 지식서비스</div><br/>
                                                                문서에 없는 내용은 웹에서 학습하여 답변하는 융통성까지!<br/>
                                                        세상의 모든 지식을 AI data integration system으로 최적화하여,<br/>
                                                        해당 정보를 사용자의 요구에 맞게 답변합니다.<br/>
                                        <img className="itemImage" src={image_1_3} />
                                        </div>
                                        {/* <div className='bottomBorder' /> */}
                                        
                                        <div className='bold40 marginB50'>Product Features</div>
                                        <div className='bottomRow'>
                                                <div className='bottomCol'>
                                                        <img className="techImage" src={image_1_5} />
                                                        <div className='bold20 marginB10'>Action perception cycling</div>
                                                        <div className='textWrap'>사람이 지식을 습득하는 것과 같은 원리를 AI에 적용합니다. AI 스스로 질문과 답변을 하며, 단순 텍스트에 불과한 방대한 문서를 지식으로서 가치 있는 텍스트로 승격시킵니다.</div>

                                                </div>
                                                <div className='bottomCol'>
                                                        <img className="techImage" src={image_1_6} />
                                                        <div className='bold20 marginB10'>Without labeled data</div>
                                                        <div className='textWrap'>기존의 인공지능 학습이란 수 많은 라벨링된 데이터가 필요합니다. 하지만 AskMe는 라벨링된 데이터가 없이, 스스로 학습하는 알고리즘을 사용합니다. 이로서 언제 어디서든, 30초내외로 문서를 학습 할 수 있습니다.</div>
                                                </div>
                                                <div className='bottomCol'>
                                                        <img className="techImage" src={image_1_7} />
                                                        <div className='bold20 marginB10'>Unlimited applications</div>
                                                        <div className='textWrap'>학습데이터가 필요 없기 때문에, 응용 분야 또한 제약이 없습니다. 금융, 세금, 회계, 의료, 법률 등의 모든 분야에서 활약을 기대합니다.</div>
                                                </div>
                                        </div>

                                        <div className='bottomBorder' />
                                        <div className='bottomEx'>
                                                <div className='bold40 marginB10 '>Example</div>

                                                <img className="itemImage" src={image_1_8} />

                                                <div className='bottomBorder' />

                                                <img className="itemImage" src={partnerM} />
                                                <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo 체험하기</a>
                                               
                                                <a href={"https://neoali.notion.site/AskMe-Landing-Pages-a2396890886d486e9481a334a59d79de"} target="_blank" className='demoButton demoButtonPosition'>분야별 특화 모델 소개와 데모</a>
                                        </div>
                                </div> 

                        </div>
                        </MediaQuery>
                </AppLayout>
        )
}

Products1.propTypes = {};

export default Products1;