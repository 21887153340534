import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../components/AppLayout';

import Fade from 'react-reveal/Fade';
import { useSpring, animated, useTransition, config, Transition } from 'react-spring'
import MediaQuery from 'react-responsive'
import { useTranslation } from "react-i18next";

const AboutUs = () => {
        // const styles = useSpring({

        //         from: { opacity: 1 },
        //         enter: { opacity: 0 },
        //         leave: { opacity: 0 },
        //         delay: 2000,
        //         config: config.molasses,
        //  })
    const { t,i18n } = useTranslation()
    const data = [
        
        {
                title: `인공지능에 대처하는 우리들의 자세 - 이민호 경북대학교 전자공학부 교수`,
                thumb: "https://www.tbc.co.kr/uploads/post/2023/03/ece197b40926f6eab0214e1d500f6177.jpg",
                sum: `인공지능은 현재 우리 사회에서 매우 중요한 역할을 하고 있습니다. 그러나 인공지능의 발전은 우리에게 다양한 문제와 과제를 제기하고 있습니다. 이러한 문제와 과제를 해결하기 위해서는 우리들이 인공지능에 대한 적극적인 대처 자세를 가져야 합니다`,
                link: "https://www.tbc.co.kr/tv?mid=2_236_240&post_id=50566"

        },
        {
                title: `이민호 에이엘아이 대표 "무엇이든 대답하는 감성 AI 동반자 만들 것"`,
                thumb: "https://www.yeongnam.com/mnt/file/202302/2023022201000723600029451.jpg",
                sum: `경북대 제자 등 우수재원 바탕 대구 신산업 이끌 솔루션 개발  에이엘아이는 세부적인 분야에 맞는 AI 솔루션을 개발할 것"이라며 "무엇이든 대답하는 감성 AI가 동반자가 되는 시대를 열겠다"고 했다.`,
                link: "https://m.yeongnam.com/view.php?key=20230222010002943"

        },
                {
                        title: `[대구경북에도 이런 기업이] 대구 AI 솔루션 기업 '에이엘아이'…자체개발 AI 엔진으로 업무효율 증대`,
                        thumb: "https://www.yeongnam.com/mnt/file/202302/2023022201000723400029431.jpg",
                        sum: "자체개발 AI엔진 'ASK ME'…복잡한 질문도 30초 안에 답변,에이엘아이는 다양한 인공지능 프로그램을 개발해 B2B(기업 대 기업), B2C(기업 대 소비자) 비즈니스 모델을 창출하고 있다. ASK ME는 학습데이터 제약이 없어 금융, 세무, 회계, 의료, 법률 등 폭넓은 활용이 가능하다.",
                        link: "https://www.yeongnam.com/web/view.php?key=20230222010002943"
   
                }
                ,
                {
                        title: `㈜인트인·㈜에이엘아이·㈜아임시스템·바이루트㈜ 미, 실리콘밸리 진출`,
                        thumb: "https://www.yeongnam.com/mnt/file/202211/2022112001000624900026441.jpg",
                        sum: "대구시는 지난 10월 5일(수) 글로벌 역량을 지닌 우수기업으로 ㈜인트인, ㈜에이엘아이, ㈜아임시스템, 바이루트(주)를 선정했고, 선정된 우수 스타트업 4개 사는 11월 14일(월)부터 미국 실리콘밸리 Plug and Play 본사에서 주최하는 해외투자자 및 세계 스타트업 대표들과의 네트워킹 프로그램인 Plug and Play Silicon Valley Summit에 참가했다.",
                        link: "http://www.thesegye.com/news/view/1065589584915729"

                },
                {
                        title: `대구창조경제혁신센터, 1일 C-Lab액셀러레이팅 14기 데모데이 개최`,
                        thumb: "https://img.etnews.com/photonews/2212/1599364_20221201154001_969_0001.jpg",
                        sum: "대구창조경제혁신센터(센터장 이재일·대구혁신센터)는 1일 대구혁신센터 1층 C-quad에서 C-Lab 액셀러레이팅 14기 데모데이를 개최했다.                        지난 6개월 간 액셀러레이팅을 마친 대구혁신센터 보육기업 성과를 선보이는 자리다. 전시부스 운영과 투자유치를 위한 IR피칭, 스타트업-투자자 간 네트워킹 세션으로 구분해 진행됐다.",
                        link: "https://www.etnews.com/20221201000275"

                },
                {
                        title: `레몬체인·에이엘아이, 블록체인 기반 AI·헬스케어 빅데이터 분석 기술 협력 위한 MOU 체결`,
                        thumb: "https://cdn.asiaa.co.kr/news/photo/202205/87877_99121_5749.jpg",
                        sum: "㈜레몬체인과 학습기반 AI 전문기업인 에이엘아이는 25일 블록체인 기반 AI · 헬스케어 빅데이터에 관련한 기술 제휴 및 업무협약을 체결했다고 밝혔다. 이번 협약을 통해 양사는 △헬스케어 빅데이터 · AI 분석 솔루션 개발 △AI기반 Dtx 플랫폼 구축 △블록체인 기반 인공지능 진단솔루션 개발 △AI 기술을 활용한 원격의료 시스템 개발 △의료 AI 공동연구 등 협력해 나가기로 했다.",
                        link: "https://www.asiaa.co.kr/news/articleView.html?idxno=87877"

                },
                {
                        title: `이수시스템, ‘에이엘아이’와 MOU..."자사 솔루션에 AI 기술 접목한다"`,
                        thumb: "https://www.paxetv.com/news/photo/202201/136241_109447_1235.png",
                        sum: "이수그룹(회장 김상범) IT 솔루션 계열사 이수시스템(대표이사 김용하)은 AI 전문기업 ‘ALI(이하 에이엘아이)’와 클라우드 인사솔루션 ‘WORKUP(이하 워크업)’의 효율 증대를 위한 기술협약을 체결했다고 19일 밝혔습니다.",
                        link: "https://www.paxetv.com/news/articleView.html?idxno=136241"

                },
                {
                        title: `이민호 경북대 교수, 융합R&D 공로로 과기정통부 장관 표창`,
                        thumb: "https://img.etnews.com/photonews/2106/1425769_20210621162519_088_0001.jpg",
                        sum: "이민호 경북대 인공지능학과·전자공학부 교수가 융합 연구개발(R&D) 유공자로 선정돼 과학기술정보통신부 장관 표창을 받았다. 과학기술·인문사회융합연구사업에서 디지털 라키비움 구축을 위한 기계학",
                        link: "https://www.etnews.com/20220104000202"

                },
                {
                        title: `“미국 진출 교두보 마련”…AI 양재 허브 기업 테크크런치서 성과 높여`,
                        thumb: "https://itimg.chosun.com/sitedata/image/202211/06/2022110600481_0.jpg",
                        sum: "AI 양재 허브 기업들이 북미 최대 스타트업 콘퍼런스인 ‘테크크런치 디스럽트(TechCrunch Distrup)’ 참가해 투자 유치 등 성과를 올린 것으로 나타났다. 테크크런치는 VC매칭, 경연대회 등 다양한 프로그램이 한 곳에서 펼쳐지는 스타트업 축제로, 올해 행사에는 구글 및 우버 등 20여개국에서 550개 이상 기업이 참가했다.",
                        link: "https://it.chosun.com/site/data/html_dir/2022/11/06/2022110600483.html"

                },
                {
                        title: `AI 전문기업 에이엘아이, 엔씨소프트로부터 시리즈A 투자 유치`,
                        thumb: "https://img.etnews.com/photonews/2106/1425769_20210621162519_088_0004.jpg",
                        sum: "오픈도메인 지식서비스 전문기업 에이엘아이(ALI·대표 이민호·경북대 교수)는 엔씨소프트(대표 김택진)로부터 공동기술 개발 연구용역을 포함해 20억원 시리즈A 투자를 유치했다고 21일 밝혔다. 에이엘아이는 이민호 경북대 인공지능연구소장이 이수영 KAIST 교수와 공동 설립한 회사다. 딥러닝으로 비디오 프레임을 분석하는 컴퓨터 비전 솔루션과 원하는 특정 패턴을 찾아 상황을 텍스트로 요약할 수 있는 기술 등을 보유하고 있다.",
                        link: "https://www.etnews.com/20210621000138"
                }

        ]
        return (
                <AppLayout>
                        <MediaQuery minWidth={1224}>
                                <div className='Press'>
                                        <div className='PressTop'>
                                                <div>
                                                {i18n.language == 'ko' ?
<>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>뉴스기사에서 ALI는?</div></Fade>
                                                        <div className='bold50 marginB10'>보도자료</div>
                                                        <div className='lineH f18'>ALI의 최신 소식을 뉴스 기사로 만나보세요.</div>
                                                        </>
                                                        :
                                                        <>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold30'>What about ALI in the news?</div></Fade>
                                                        <div className='bold50 marginB10'>Press</div>
                                                        <div className='lineH f18'>Get the latest news on ALI.</div>
                                                        </>
}

                                                </div>

                                        </div>

                                        <div className='PressCenter'>
                                                <div className='row'>
                                                        {data.length && data.map((item) =>
                                                                <a href={item.link} target='blank' className='item'>
                                                                        <div className='itemTop'>
                                                                                {item.title}

                                                                        </div>
                                                                        <img src={item.thumb} className='thumb' />

                                                                        <div className='itemBottom'>
                                                                                {item.sum}
                                                                        </div>
                                                                </a>

                                                        )}

                                                </div>

                                        </div>
                                        <div className='aboutUsBottom'>



                                        </div>
                                </div>
                        </MediaQuery>
                        {/*모바일//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                        <MediaQuery maxWidth={1224}>
                                <div className='Press'>
                                        <div className='PressTop'>
                                                <div>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold20'>뉴스기사에서 ALI는?</div></Fade>
                                                        <div className='bold50 marginB10'>보도자료</div>
                                                        <div className='lineH f16'>ALI의 최신 소식을 뉴스 기사로 만나보세요.</div>

                                                </div>

                                        </div>

                                        <div className='PressCenter'>
                                                <div className='col'>
                                                {data.length && data.map((item) =>
                                                                <a href={item.link} target='blank' className='item'>
                                                                        <div className='itemTop'>
                                                                                {item.title}

                                                                        </div>
                                                                        <img src={item.thumb} className='thumb' />

                                                                        <div className='itemBottom'>
                                                                                {item.sum}
                                                                        </div>
                                                                </a>

                                                        )}
                                        </div>
                                        </div>
                                        <div className='aboutUsBottom'>



                                        </div>
                                </div>
                        </MediaQuery>
                </AppLayout>
        )
}

AboutUs.propTypes = {};

export default AboutUs;