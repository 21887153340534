import React, { useCallback, useEffect, useState } from 'react';
import AppLayout from '../components/AppLayout';
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useTrail, useSpring, animated } from 'react-spring'
import image_1_1 from '../assets/image_1_1.png'
import image_1_2 from '../assets/image_1_2.png'
import image_1_3 from '../assets/image_1_3.png'
import image_1_4_3 from '../assets/image_1_4_3.png'
import image_1_5 from '../assets/image_1_5.png'
import image_1_6 from '../assets/image_1_6.png'
import image_1_7 from '../assets/image_1_7.png'
import image_1_8 from '../assets/image_1_8.png'
import partner from '../assets/partner.png'
import partnerM from '../assets/partnerM.png'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import MediaQuery from 'react-responsive'
const Home = () => {
        const demoLink="https://askme-plus.web.app"
    return (
                <AppLayout>
                        <MediaQuery minWidth={1224}>
                        <div className="home">
                                <div className='homeTop'>
                                        <div className='textContents'>
                                                <Fade left cascade duration={2000} delay={200}><div className='bold20'>인공지능이 대신 공부해서 알려 드립니다.</div></Fade>
                                                <div className='bold50 marginB10'>AskMe</div>
                                              
                                                <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo 체험하기</a>
                                                <a href={"https://neoali.notion.site/AskMe-Landing-Pages-a2396890886d486e9481a334a59d79de"} target="_blank" className='demoButton demoButtonPosition'>분야별 특화 모델 소개와 데모</a>
                                        </div>
                                        <div className='textContents'>
                                                <Fade left cascade duration={2000} delay={200}><div className='bold20'>텍스트 어드벤처 챗봇!</div></Fade>
                                                <div className='bold50 marginB10'>티키타카</div>
                                              
                                                <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo 체험하기</a>
                                                
                                        </div>
{/*                                         
                                        <div className='textContents'>
                                                <Fade left cascade duration={2000} delay={200}><div className='bold20'>인공지능이 대신 글을 써드립니다.</div></Fade>
                                                <div className='bold50 marginB10'>Write Me</div>
                                              
                                                <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo 체험하기</a>
                                        </div> */}

                                        <div className='textContents'>
                                                <Fade left cascade duration={2000} delay={200}><div className='bold20'>메타버스 극장으로 초대합니다.</div></Fade>
                                                <div className='bold50 marginB10'>같이워치</div>
                                              
                                                <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo 체험하기</a>
                                        </div>

                                       {/* <div className='textContents'>
                                                <Fade left cascade duration={2000} delay={200}><div className='bold20'>K-Pop Chat</div></Fade>
                                                <div className='bold50 marginB10'>K-Pop Chat</div>
                                              
                                                <a href={"https://www.notion.so/neoali/642678e986814119ae4deb9f575fede4"} target="_blank" className='demoButton demoButtonPosition'>Join</a>
                                        </div>*/}

                                </div>
                        

                        </div>
                        </MediaQuery>
                {/*모바일//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                        <MediaQuery maxWidth={1224}>
                        <div className="home">
                                        <div className='homeTop'>
                                                <div className='textContents'>
                                                        <Fade left cascade duration={2000} delay={200}><div className='bold20'>인공지능이 대신 공부해서 알려 드립니다.</div></Fade>
                                                        <div className='bold50 marginB10'>AskMe</div>
                                                        <div className='text'>

                                                        <div className='lineH f16'>계약서, 보험약정서, 회계 등의 어려운 문서를 직접 읽으시나요?</div>
                                                        <div className='lineH f16'>이젠 고민하지 마세요.</div>
                                                        <div className='lineH f16'>30초만 주세요. AI가 대신 공부해서 알려 드립니다.</div>
                                                </div>
                                                <a href={demoLink} target="_blank" className='demoButton demoButtonPosition'>demo 체험하기</a>
                                                <a href={"https://neoali.notion.site/AskMe-Landing-Pages-a2396890886d486e9481a334a59d79de"} target="_blank" className='demoButton demoButtonPosition'>분야별 특화 모델 소개와 데모</a>
                                                </div>

                                        </div>
                                </div>
                               

                        </MediaQuery>
                </AppLayout>
        )
}

Home.propTypes = {};

export default Home;
